import React, { useEffect, useState } from "react";
import SearchInput from "../Inputs/SearchInput";
import InputSelect from "../Inputs/InputSelect";
import DatePickerInput from "../Inputs/DatePickerInput";
import Select from "../Users/Select";
import ClockIcon from "../Sharing/ClockIcon";
import dayjs from "dayjs";
import { parseHoursToInt } from "../../services/data";

const daysOptions = [
  {
    label: "Últimas 24hrs",
    value: "last_24_hrs",
  },
  {
    label: "Esta semana",
    value: "this_week",
  },
  {
    label: "Última semana",
    value: "last_week",
  },
  {
    label: "Hoy",
    value: "today",
  },
  {
    label: "Últimos 90 días",
    value: "last_90_days",
  },
  /*{
    label: "Este año",
    value: "this_year",
  },
  {
    label: "Últimos 12 meses",
    value: "last_12_months",
  },*/
];

const hoursOptions = [
  {
    label: "00:00",
    value: "00:00",
  },
  {
    label: "01:00",
    value: "01:00",
  },
  {
    label: "02:00",
    value: "02:00",
  },
  {
    label: "03:00",
    value: "03:00",
  },
  {
    label: "04:00",
    value: "04:00",
  },
  {
    label: "05:00",
    value: "05:00",
  },
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "20:00",
    value: "20:00",
  },
  {
    label: "21:00",
    value: "21:00",
  },
  {
    label: "22:00",
    value: "22:00",
  },
  {
    label: "23:00",
    value: "23:00",
  },
  {
    label: "23:59",
    value: "23:59",
  },
];

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  handleSelectChange,
  values,
  disabled,
  handleDaysChange,
  minEndDate,
  maxStartDate,
}) {
  const [endTime, setEndTime] = useState([]);

  const calculateEndTimeoptions = () => {
    const currentTime = dayjs().hour() + 1;
    return hoursOptions.filter(
      ({ value }) => parseHoursToInt(value) <= currentTime
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setEndTime(calculateEndTimeoptions);
    }, 15000);

    setEndTime(calculateEndTimeoptions());

    return () => clearInterval(interval);
  }, []);

  return (
    <form
      className="container-fluid p-0 justify-content-start d-flex gap-1 gestion_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div style={{ maxWidth: "128px" }} className="container-fluid p-0 m-0">
        <SearchInput
          onChange={handleChange}
          value={values.search}
          label="Buscar"
          id="search"
          name="search"
          type="search"
          valid={true}
          rounded={true}
        />
      </div>
      {/* <div style={{ maxWidth: "108px" }} className="container-fluid p-0 m-0">
        <InputSelect
          labelProp={"Todos los nodos"}
          rounded={true}
          max_width={151}
          options={horasOptions}
          idKey="hora"
          labelKey="hora"
        />
      </div> */}
      <div
        style={{ maxWidth: "96px" }}
        className="container-fluid p-0 m-0 datePicker-input-container"
      >
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.start_date}
          label="Desde:"
          id="start_date"
          name={`start_date`}
          valid={true}
          rounded={true}
          maxDate={maxStartDate}
          minDate={dayjs().subtract(90, "day")}
        />
      </div>
      <div style={{ maxWidth: "75px" }} className="container-fluid p-0 m-0">
        <Select
          id={`start_hours`}
          name={`start_hours`}
          label=""
          value={values.start_hours}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={hoursOptions}
          valueKey="value"
          labelKey="label"
          arrow="white"
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      <div
        style={{ maxWidth: "96px" }}
        className="container-fluid p-0 m-0 datePicker-input-container"
      >
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.end_date}
          label="Hasta:"
          id="end_date"
          name={`end_date`}
          valid={true}
          rounded={true}
          minDate={minEndDate ?? dayjs().subtract(90, "day")}
        />
      </div>
      <div style={{ maxWidth: "75px" }} className="container-fluid p-0 m-0">
        <Select
          id={`end_hours`}
          name={`end_hours`}
          label=""
          value={values.end_hours}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={endTime}
          valueKey="value"
          labelKey="label"
          arrow="white"
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      {/*<div style={{ maxWidth: "151px" }} className="container-fluid p-0 m-0">
        <InputSelect
          initial_value={values?.type_event}
          labelProp={values?.type_event?.event}
          setValueProp={(value) =>
            handleSelectChange({
              name: "type_event",
              value,
            })
          }
          rounded={true}
          max_width={151}
          options={typeEventOptions}
          idKey="key"
          labelKey="event"
        />
      </div>*/}
      {
        <div style={{ maxWidth: "52px" }} className="container-fluid p-0 m-0">
          <Select
            id={`days`}
            name={`days`}
            label=""
            value={values.days}
            // handleSetValue={(e) => console.log(e)}
            handleSetValue={handleDaysChange}
            options={daysOptions}
            valueKey="value"
            labelKey="label"
            rounded={true}
            max_width={52}
            arrowWidth={5}
          />
        </div>
      }
      <button type="submit" className="d-none"></button>
    </form>
  );
}
