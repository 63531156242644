import React, { useState } from "react";

const ModalCardMedPages = ({ children }) => {
  return (
    <div
      className="modal_container position-fixed  w-100  top-0  start-0  overflow-x-hidden overflow-y-auto min-vh-100"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        // className={`modal_card_container change_password_modal modal_twofactor ${modalShort ? "modal-block-maxheight" : "modal-standart-maxheight"}`}
        className="modal_card_container change_password_modal modal_twofactor modal-standart-maxheight"
      >
        <div className="overflow-x-hidden mx-auto">
          <div className="row m-0" style={{ minWidth: "200px", width:"100%" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCardMedPages;
