import React, { useReducer, useEffect, useContext, useMemo } from "react";
import Checkbox from "../Inputs/Checkbox";
import { v4 as uuidv4 } from "uuid";
import { InputToggleContext } from "../Inputs/InputToggle";
import { createPortal } from "react-dom";

const useInputToggleContext = () => {
  try {
    const context = useContext(InputToggleContext);
    if (!context) {
      throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
  } catch (error) {
    return {
      handleOpen: () => {
        console.warn('handleOpen is not available because UserProvider is missing');
      },
    };
  }
} 


const useAssignRolForm = ({ values, handleCheckbox, roles, handleResetRoleIds }) => {
  const { handleOpen } = useInputToggleContext();

  const handleAdd = () => {
    handleOpen();
  };

  const handleCancel = () => {
    handleResetRoleIds();
    handleOpen();
  };

  const rolesInputs = useMemo(
    () =>
      roles.map(({ id, name }) => (
        <div className="col-6 ">
          <Checkbox
            key={`_${id}_${name}`}
            bold={true}
            onChange={handleCheckbox}
            id={id}
            name={id}
            label={name}
            value={values.includes(id)}
          />
        </div>
      )),
    [roles, values]
  );

  return {
    rolesInputs,
    handleAdd,
    handleCancel
  };
};

export default function AssignRolesForm({ values, handleCheckbox, roles, handleResetRoleIds, onClick = null, close = null }) {
  const { 
    rolesInputs, 
    handleAdd,
    handleCancel 
  } =
    useAssignRolForm({
      values,
      handleCheckbox,
      roles,
      handleResetRoleIds,
      onClick
    });
  return createPortal(
    <div
      style={{ display: "grid", placeItems: "center"}}
      className={`
        modal_container
        `}
    >
      <div className={"modal_card_container bg-white assign_rol_card d-flex justify-content-center"}>
        <div style={{ maxWidth: "399px"}} className="d-flex flex-column">
          <p style={{ marginBottom: "33px" }} className="card_title">Asignación de roles</p>
          <p style={{ marginBottom: "29px" }} className="card_text">Asigna uno o varios roles con base en las funciones que tendrá el usuario</p>
          <div style={{ rowGap: "12px" }} className="row m-0 p-0 container">
            {rolesInputs}
          </div>
          <div style={{ paddingTop: "125px" }} className="d-flex gap-3">
            <button disabled={values.length === 0} onClick={onClick ? onClick : handleAdd} type="button" className={`button ${values.length === 0 ? 'button_disabled' : 'button_active'}`}>
              Asignar roles
            </button>
            <button onClick={close ? close : handleCancel} type="button" className={"cancel_button"}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
}
