import React from "react";

export default function IconCirculoCredito() {
  return (
    <div className="d-flex justify-content-center container-icon_circulo_credito_general">
      <div className="icon_circulo_credito_general">
        <img alt="" src="/images/dicio/circulo_credito_icon.svg" />
      </div>
    </div>
  );
}
