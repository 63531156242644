import React from 'react'
import ArrorPreviewPagination from "../Table/ArrorPreviewPagination";
import ArrorNextPagination from "../Table/ArrorNextPagination";

export default function Pagination({
  pagination,
  handlePreviousPage,
  handleSelectedPage,
  handleNextPage,
}) {
  return (
    <>
      {pagination?.totalElements > 10 && (
          <div className="container-fluid d-flex px-2 py-3 justify-content-center align-items-center">
            <div className="d-flex gap-2">
              <button
                disabled={pagination.page === 1}
                onClick={handlePreviousPage}
                className={`rounded-circle d-flex justify-content-center align-items-center bg-transparent ${pagination.page === 1 ? 'button_pagination_enabled' : 'button_pagination_inactive'}`}
              >
                <ArrorPreviewPagination />
              </button>
              {(pagination.hasFirstNumber || (pagination.page !== 1 && pagination.totalPages === 3)) && (
                <button
                  onClick={() => handleSelectedPage(1)}
                  className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                >
                  {pagination.firstNumber}
                </button>
              )}
              {(pagination.hasPreviousPage ||
                (!pagination.hasPreviousPage && !pagination.hasNextPage)) && pagination.totalPages > 3 && (
                <button className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_enabled">
                  {"..."}
                </button>
              )}
              {pagination.hasPreviousPage && (
                <button
                  onClick={() => handleSelectedPage(pagination.previousPage)}
                  className={`${
                    pagination.previousPage > 99
                      ? "rounded-pill"
                      : "rounded-circle"
                  } d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive`}
                >
                  {pagination.previousPage}
                </button>
              )}
              <button
                className={`${
                  pagination.page > 99 ? "rounded-pill" : "rounded-circle"
                } d-flex justify-content-center align-items-center bg-transparent button_pagination_active`}
              >
                {pagination.page}
              </button>
              {pagination.hasNextPage && (
                <button
                  onClick={() => handleSelectedPage(pagination.nextPage)}
                  className={`${
                    pagination.nextPage > 99 ? "rounded-pill" : "rounded-circle"
                  } d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive`}
                >
                  {pagination.nextPage}
                </button>
              )}
              {pagination.hasNextPage && pagination.totalPages > 3 && (
                <button className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_enabled">
                  {"..."}
                </button>
              )}
              {pagination.hasLastNumber && (
                <button
                  onClick={() => handleSelectedPage(pagination.lastNumber)}
                  className={`${
                    pagination.lastNumber > 99
                      ? "rounded-pill"
                      : "rounded-circle"
                  } d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive`}
                >
                  {pagination.lastNumber}
                </button>
              )}
              <button
                disabled={pagination.page === pagination.totalPages}
                onClick={handleNextPage}
                className={`rounded-circle d-flex justify-content-center align-items-center bg-transparent ${pagination.page === pagination.totalPages ? 'button_pagination_enabled' : 'button_pagination_inactive'}`}
              >
                <ArrorNextPagination />
              </button>
            </div>
          </div>
        )}
    </>
  )
}
