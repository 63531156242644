import React from "react";
import { NavLink } from "react-router-dom";

export default function Link({ path, children }) {
  const catalogoLogos = {
    dashboard: "url('/images/nav/dashboard.webp')",
    transacciones: "url('/images/nav/transactions.webp')",
    nodos: "url('/images/nav/nodes.webp')",
    usuarios: "url('/images/nav/users.webp')",
    gestion: "url('/images/nav/database.webp')",
    actividad: "url('/images/nav/Eventos.svg')",
    ajustes: "url('/images/nav/settings.webp')",
  };
  return (
    <NavLink
      to={`/${path}`}
      className={
        "dco_nav_link " +
        (window.location.pathname.replaceAll("/", "") === path &&
          "dco_nav_link_active")
      }
    >
      <span style={{ backgroundImage: catalogoLogos[path] }}></span>
      {children}
    </NavLink>
  );
}
