import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useReducer,
  useRef,
  useCallback,
} from "react";
import Table from "../Table/Container";
import Actions from "./Actions";
import Status from "./Status";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import {
  downloadInfoCargaDB,
  downloadInfoDocument,
  getAllLogsServiceDB,
  getCatalogs,
  getManagementDashboard,
} from "../../services/api";
import { Permisos } from "../../services/permisos.js";
// import ExportManagement from "./ExportManagement";
import ExportData from "./ExportData";
import ToAccordion from "../Accordion/ToAccordion.jsx";
import FilterForm from "./FilterForm";
import {
  createDatePickerChangeHandler,
  formatIDSummary,
  getEndDateAndTime,
  handleGetCatalogs,
  validateEmptyValues,
} from "../../services/data";
import ArrorPreviewPagination from "../Table/ArrorPreviewPagination";
import ArrorNextPagination from "../Table/ArrorNextPagination";
import { useErrorReducer } from "../../services/data.js";
import Pagination from "../Table/Pagination.jsx";
import usePaginationBack from "../Table/usePaginationBack.js";
import AlertaGeneral from "../Alerts/AlertaGeneral";

export const CargasBDContext = createContext();

const formatHour = (hour) => {
  if (hour < 0 || hour > 23) {
    //throw new Error("Hour must be between 0 and 23");
    return "23:59";
  }
  const formattedHour = hour.toString().padStart(2, "0");
  return `${formattedHour}:00`;
};

const INITIAL_VALUES = {
  search: "",
  start_date: dayjs().subtract(90, "day").format("YYYY-MM-DD"),
  end_date: dayjs().format("YYYY-MM-DD"),
  start_hours: formatHour(dayjs().hour()),
  end_hours: formatHour(dayjs().hour() + 1),
  response_time: [],
  days: "last_90_days",
  first_mount: true,
  all_nodes: "",
  all: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export default function Body() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [scoreLimits, setScoreLimits] = useState({
    min: 10,
    max: 100,
  });
  const [responseTimeLimits, setResponseTimeLimits] = useState({
    min: 10,
    max: 100,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(new AbortController());
  const [orderConfig, setOrderConfig] = useState({
    key: "nodo_name",
    direction: "desc",
  });
  const { errorValues, handleError } = useErrorReducer();

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();

  // Handlers
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = useCallback((msj) => {
    setSuccessMessage(msj);
  }, []);

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleResetPagination(); // Reinicia el paginado y manda a la pagina 1
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleDaysChange = (values) => {
    handleResetPagination();
    const { field, value } = values;
    setFieldValue(field, value);
  };

  const handleSelectChange = (values) => {
    handleResetPagination();
    const { name, value } = values;
    setFieldValue(name, value);
  };

  // Inicio Ajustes para validar que el rango de fecha no sea menor a la fecha de inicio (Revisar props del filtro)
  const handleDatePickerChange = createDatePickerChangeHandler({
    setter: setFieldValue,
    startDate: values.start_date,
    endDate: values.end_date,
  });

  const minEndDate = values.start_date ? dayjs(values.start_date) : undefined;
  const maxStartDate = values.end_date ? dayjs(values.end_date) : undefined;

  // Inicio Ajustes para validar que el rango de fecha no sea menor a la fecha de inicio (Revisar props del filtro)

  const last_24_hrs = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const this_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs()
          .subtract(dayjs().day() - 1, 'day')
          .format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const today = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue("start_date", dayjs().format('YYYY-MM-DD'));
      setFieldValue("start_hours", "00:00");
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_90_days = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const this_year = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(dayjs().month(), 'month').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_12_months = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const daysController = useMemo(
    () => ({
      last_24_hrs: last_24_hrs,
      this_week: this_week,
      last_week: last_week,
      today: today,
      last_90_days: last_90_days,
      this_year: this_year,
      last_12_months: last_12_months,
    }),
    [
      last_12_months,
      last_24_hrs,
      last_90_days,
      last_week,
      this_week,
      this_year,
      today,
    ]
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]();
    }
    return () => {
      abortControllerRef.current.abort();
    };
  }, [daysController, values.days]);

  const handlePageInformation = ({ totalPages, page }) => {
    const HasPreviousPage = page > 1;
    const HasNextPage = page < totalPages;
    return {
      HasPreviousPage,
      HasNextPage,
      NextPageNumber: HasNextPage ? page + 1 : null,
      PreviousPageNumber: HasPreviousPage ? page - 1 : null,
    };
  };

  const handleGetCargasDB = useCallback(
    async ({ ...props }) => {
      // debugger
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        handleLoading();
        const noEmptyValues = validateEmptyValues(values);
        const response = await getAllLogsServiceDB({
          // Consumios el endpoint
          page: pagination.page,
          ...noEmptyValues,
          signal: abortControllerRef.current.signal,
          OrderAscending: orderConfig.direction,
          OrderBy: orderConfig.key,
        });
        if (!response.success) {
          handleLoading();
          if (response.name === "AbortError") {
            setTableData([]);
            console.log("Request canceled");
            return;
          }
          handleError("increment", response.message);
          setTimeout(() => {
            handleError("decrement", "");
          }, 1500);
          return;
        }
        handleLoading();
        setTableData(response?.data?.list ?? []);
        handleValidateTotalPages({
          totalPages: response?.data?.totalPages,
          page: response?.data?.page,
          totalElements: response?.data?.totalElements,
        });
        setScoreLimits({
          min: response?.data?.min_super_score,
          max: response?.data?.max_super_score,
        });
        setResponseTimeLimits({
          max: response?.data?.max_tiempo_respuesta,
          min: response?.data?.min_tiempo_respuesta,
        });
        return;
      } catch (error) {
        console.log("Error en el handler");
        console.error(error);
        if (error.name === "AbortError") {
          console.log("Request canceled");
          return;
        }
        setLoading(false);
        setTableData([]);
        handleError("increment", "Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleError("decrement", "");
        }, 1500);
        return;
      }
    },
    [handleValidateTotalPages, pagination.page, values, orderConfig]
  );

  const availableDownloads = {
    excel: {
      params: "&formato=xls",
      name: "CargaBD.xls",
    },
    csv: {
      params: "&formato=csv",
      name: "CargaBD.csv",
    },
    pdf: {
      params: "&formato=pdf",
      name: "CargaBD.pdf",
    },
  };

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        handleLoading();
        const response = await downloadInfoCargaDB({
          signal: abortControllerRef.current.signal,
          ...values,
          download: availableDownloads[selected],
        });
        if (!response.success) {
          handleLoading();
          handleError("increment", response.message);
          setTimeout(() => {
            handleError("decrement", response.message);
          }, 1500);
          return;
        }
        handleLoading();
        handleSuccess();
        handleSuccessMessage(
          "La exportación de datos se ha realizado con éxito"
        );
        setTimeout(() => {
          handleSuccess();
          handleModal();
          handleSuccessMessage("");
        }, 1500);
      } catch (error) {
        console.log(error);
        handleLoading();
        if (
          error.toString().includes("NETWORK_CONNECTION") ||
          error.toString().includes("Failed to fetch")
        )
          handleError(
            "increment",
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        else handleError("increment", "Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleError("decrement", "Lo sentimos ocurrió un error");
        }, 1500);
        return;
      }
    },
    [values]
  );

  useEffect(() => {
    handleGetCargasDB({});
  }, [pagination.page, values, orderConfig]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Nodo",
        selector: (row) => row?.idNodo ?? "",
        className: "table_header_cell",
        key_name: "idNodo",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "105px",
          maxWidth: "105px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "105px",
          maxWidth: "105px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "ID de carga",
        selector: (row) => row?.idCarga ?? "",
        className: "table_header_cell",
        key_name: "idCarga",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "154px",
          maxWidth: "154px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "154px",
          maxWidth: "154px",
        },
        cell_classname: "table_row_cell_id_transaction",
      },
      {
        name: "Fecha/Hora de carga",
        selector: (row) =>
          dayjs
            .utc(row?.fechaHora)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        className: "table_header_cell",
        key_name: "fechaHora",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "103px",
          maxWidth: "178px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "103px",
          maxWidth: "178px",
        },
        cell_classname: "table_row_cell_id_transaction",
      },
      {
        name: "Total de registros",
        selector: (row) => row?.total_registros ?? "",
        className: "table_header_cell",
        key_name: "total_registros",
        service: handleOrderByColumns,
        style: {
          width: "100%",
          minWidth: "83px",
          maxWidth: "185px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "83px",
          maxWidth: "185px",
        },
        cell_classname: "table_row_cell_id_transaction",
      },
      {
        name: "Total de errores",
        selector: (row) => row?.total_errores ?? "",
        className: "table_header_cell",
        service: handleOrderByColumns,
        key_name: "total_errores",
        style: {
          width: "100%",
          minWidth: "75px",
          maxWidth: "153px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "75px",
          maxWidth: "153px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Total de duplicados",
        selector: (row) => row?.total_duplicados ?? "",
        className: "table_header_cell",
        service: handleOrderByColumns,
        key_name: "total_duplicados",
        style: {
          width: "100%",
          minWidth: "93px",
          maxWidth: "148px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "93px",
          maxWidth: "148px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Prom. índice calidad de registro",
        selector: (row) => row?.avgQualityScore ?? "",
        className: "table_header_cell",
        service: handleOrderByColumns,
        key_name: "avgQualityScore",
        style: {
          width: "100%",
          minWidth: "151px",
          maxWidth: "200px",
        },
        cell_styles: {
          width: "100%",
          minWidth: "151px",
          maxWidth: "200px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
  ]);

  return (
    <CargasBDContext.Provider
      value={{ dataTable, handleError, handleDownloadData }}
    >
      {
        <div
          className="container-fluid d-flex justify-content-end padding-right-0 p-0 align-items-end justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
          style={{ marginTop: "12px" }}
        >
          <ToAccordion breackpoint={1030} icon={null} label="Filtros">
            <FilterForm
              handleDatePickerChange={handleDatePickerChange}
              handleChange={handleChange}
              handleDaysChange={handleDaysChange}
              handleSelectChange={handleSelectChange}
              values={values}
              scoreLimits={scoreLimits}
              responseTimeLimits={responseTimeLimits}
              minEndDate={minEndDate}
              maxStartDate={maxStartDate}
            />
          </ToAccordion>
          {Permisos.verificarPermiso("Cargas BD", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap mt-3">
              {<ExportData />}
            </div>
          )}
        </div>
      }
      <div
        style={{ marginTop: "11px" }}
        className="container-fluid d-flex flex-column p-0 dco-table-gestion-container"
      >
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {errorValues.error !== 0 && (
        <AlertaGeneral type={"error"}>{errorValues.message}</AlertaGeneral>
      )}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </CargasBDContext.Provider>
  );
}
