import React from "react";
import VerificarPassword from "../Users/VerificarPassword";
import NavBarMobile from "./NavBarMobile";
import NavBarDesktop from "./NavBarDesktop";
import PerfilInfo from "./PerfilInfo";

export default function Template({ children, overflowX = "auto" }) {
  return (
    <div
      className={`container-fluid vh-100 d-flex flex-column flex-md-row main_template_container`}
    >
      <NavBarDesktop />
      <NavBarMobile />
      <div
        className={`rounded bg-white d-flex m-auto m-md-0 flex-column justify-content-start align-items-start h-100 w-100 template_container template_container_padding`}
        style={{ overflowX: overflowX, position: "relative" }}
      >
        {children}
        {<VerificarPassword />}
      </div>
    </div>
  );
}
