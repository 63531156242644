import React from "react";
export default function TituloPrincipal({ titulo, subtitulo, color = '#22135E', number }) {
  return (
    <>
      <div className="d-flex flex-column">
        <p className="dco_graficas-title m-0" style={color ? { color } : {}}>
          {titulo}
          {number &&
            <b>
              {number.toLocaleString()}
            </b>
          }
        </p>
        <p className="dco_graficas-subtitle">
          {subtitulo}
        </p>
      </div>
      {/* <div className={"dco_graficas-contenedorTitulos"}>
        <label className={"dco_graficas-title"} style={{color: color}}>{titulo}</label>
        <label className={"dco_graficas-subtitle"}>{subtitulo}</label>
      </div> */}
    </>
  );
}
