import React from "react";

export default function ArrorNextPagination() {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.164898 0.152804C0.38476 -0.0509347 0.741268 -0.0509347 0.96113 0.152805L4.71466 3.63108C4.93452 3.83482 4.93452 4.16518 4.71466 4.36892L0.961129 7.8472C0.741267 8.05093 0.384759 8.05093 0.164897 7.8472C-0.0549655 7.6434 -0.0549654 7.31309 0.164897 7.10935L3.52026 4L0.164897 0.890647C-0.0549646 0.686908 -0.0549645 0.356543 0.164898 0.152804Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1649 0.152804C4.38476 -0.0509347 4.74127 -0.0509347 4.96113 0.152805L8.71466 3.63108C8.93452 3.83482 8.93452 4.16518 8.71466 4.36892L4.96113 7.8472C4.74127 8.05093 4.38476 8.05093 4.1649 7.8472C3.94503 7.6434 3.94503 7.31309 4.1649 7.10935L7.52026 4L4.1649 0.890647C3.94504 0.686908 3.94504 0.356543 4.1649 0.152804Z"
        fill="inherit"
      />
    </svg>
  );
}
