import React, { useState } from "react";
import LoginCard from "../components/Login/LoginCard";
import FormCorreoRecuperar from "../components/RecuperarPassword/FormCorreoRecuperar";
import FormNewPasswordRecuperar from "../components/RecuperarPassword/FormNewPasswordRecuperar";

const RecuperarPassword = () => {
    return (
        <LoginCard>
            {/*<FormCorreoRecuperar/>*/}
            <FormNewPasswordRecuperar/>
        </LoginCard>
    
    
    )
}

export default RecuperarPassword;