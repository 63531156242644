import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import Body from "../components/Users/Body";
import { Permisos } from "../services/permisos";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  Permisos.refrescarPrivilegios();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };
  useEffect(() => {
    if (!Permisos.verificarPermiso("Usuario", "Ver la sección")) cerrarSesion();
  }, []);

  return (
    <Template>
      <PageHeader>
        <PageTitle>Usuarios</PageTitle>
        <PageSubtitle>
          Ve el listado de usuarios, agrega o edita usuarios en el nodo
        </PageSubtitle>
      </PageHeader>
      <Body />
    </Template>
  );
};

export default Users;
