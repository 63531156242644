import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import NodosComponent from "../components/Nodos/NodosComponent";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import { Permisos } from "../services/permisos";
import { useNavigate } from "react-router-dom";

const Nodos = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Nodos", "Ver la sección")) cerrarSesion();
  }, []);
  return (
    <Template>
      <PageHeader>
        <PageTitle>Estatus del nodo</PageTitle>
        <PageSubtitle>Ve el estatus actual del nodo</PageSubtitle>
      </PageHeader>
      <NodosComponent />
    </Template>
  );
};

export default Nodos;
