import React from "react";

export default function PhoneValidation({ valid }) {
  return (
    <>
      {!valid && (
        <div className="container email_tooltip_validator">
          <p
            style={{ ...(!valid ? { color: "red" } : {}) }}
            className={`
          email_tootlip_validator_text 
          email_tootlip_validator_text_point
          ${
            !valid
              ? "email_tootlip_validator_text_error"
              : "email_tootlip_validator_text_neutral"
          }
          `}
          >
            Teléfono ingresado invalido
          </p>
        </div>
      )}
    </>
  );
}
