import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginComponent from "../components/Login/LoginComponent";
import LoginCard from "../components/Login/LoginCard";


const Login = () => {
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');


    useEffect(() => {

    }, [])

    useEffect(() => {
        localStorage.setItem("token", token);
    }, [token])

    const login = async () => {
        
    }

    return (
        <LoginCard>
            <LoginComponent/>
        </LoginCard>
    )
}

export default Login