import React from "react";
import FormCorreoRecuperar from "../components/RecuperarPassword/FormCorreoRecuperar";
import LoginCard from "../components/Login/LoginCard";

const Ajustes = () => {
  return (
    <LoginCard>
        <FormCorreoRecuperar />
    </LoginCard>
  );
};

export default Ajustes;
