import React, { useState, useEffect, Fragment } from 'react'
// Resources
const Arrowws = "/images/dashboard/cargas.svg";
const Database = "/images/dashboard/dashboard_db.png";
const TimeAverage = "/images/dashboard/time_average.svg";
const PictureQuality = '/images/dashboard/picture_quality.svg';
const Transactions = "/images/dashboard/dashboard_transactions.png";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

export default function Statistics({ statisticsData }) {
  
  return (
    <Fragment key="stadistics_nodo">
      <div style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }} className='container-fluid d-flex px-3'>
        <div className='dashboard_cards justify-content-between d-flex flex-column text_container_cards'>
          <div className='d-flex align-items-end' style={{ gap: "9px" }}>
            <img style={{ marginBottom: "0px" }} src={Database} alt='Database icon' width={"23.66px"} height={"28px"} />
            <p className='main_title' style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}>{(statisticsData?.noRegistros)?.toLocaleString() ?? "0"}</p>
          </div>
          <p className='dashboard_text m-0 text_statistics_tags'>Registros en el nodo</p>
        </div>
        <div className='dashboard_cards justify-content-between d-flex flex-column text_container_cards'>
          <div className='d-flex align-items-end dashboard_petitions_text_container' style={{ gap: "54px" }}>
            <div className='d-flex align-items-end' style={{ gap: "9px" }}>
              <img style={{ marginBottom: "2px" }} src={Transactions} alt='Database icon' width={"28.48px"} height={"20px"} />
              <p className='main_title' style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}>{(statisticsData?.peticionesTransacciones?.peticiones_validacion_identidad)?.toLocaleString() ?? "0"}</p>
            </div>
          </div>
          <p className='dashboard_text m-0 text_statistics_tags'>Transacciones en el nodo</p>
        </div>
      </div>
      <div style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }} className='container-fluid d-flex px-3'>
        <div className='dashboard_cards justify-content-between d-flex flex-column text_container_cards'>
          <div className='d-flex align-items-end' style={{ gap: "9px" }}>
            <img style={{ marginBottom: "0px" }} src={Arrowws} alt='Database icon' width={"32.7px"} height={"25.58px"} />
            <p className='main_title' style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}>{statisticsData?.no_Cargas?.toLocaleString() ?? "0"}</p>
          </div>
          <p className='dashboard_text m-0 text_statistics_tags'>Cargas en el nodo</p>
        </div>
        <div className='dashboard_cards justify-content-between d-flex flex-column text_container_cards'>
          <div className='d-flex align-items-end' style={{ gap: "9px" }}>
            <img style={{ marginBottom: "0px" }} src={TimeAverage} alt='Database icon' width={"28px"} height={"28px"} />
            <p className='main_title' style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}>{statisticsData?.tiempoPromedio?.toLocaleString() ?? "0"}<small className='main_title'>seg</small></p>
          </div>
          <p className='dashboard_text m-0 text_statistics_tags'>Promedio tiempo de respuesta</p>
        </div>
        <div className='dashboard_cards justify-content-between d-flex flex-column text_container_cards'>
          <div className='d-flex align-items-end dashboard_petitions_text_container' style={{ gap: "54px" }}>
            <div className='d-flex align-items-end' style={{ gap: "9px" }}>
              <img style={{ marginBottom: "2px" }} src={PictureQuality} alt='Database icon' width={"28px"} height={"28px"} />
              <p className='main_title' style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}>{statisticsData?.calidadFotoPromedio?.toLocaleString() ?? "0"}</p>
            </div>
          </div>
          <p className='dashboard_text m-0 text_statistics_tags'>Promedio índice de calidad de la fotografía</p>
        </div>
      </div>
    </Fragment>
  )

}
