import React from "react";

export default function Help({ marginTop = 60 }) {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ marginTop: marginTop + "px" }}
    >
      <p style={{ fontSize: "12px" }} className="fw-lighter m-0">¿Necesitas ayuda?</p>
      <a
        className={`fw-bold forgot_link`}
        target="_blank"
        href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
      >
        Presiona aquí
      </a>
    </div>
  );
}
