import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import Body from "../components/Management/Body";
import { Permisos } from "../services/permisos";
import { useNavigate } from "react-router-dom";
const Management = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };
  useEffect(() => {
    if (!Permisos.verificarPermiso("Cargas BD", "Ver la sección"))
      cerrarSesion();
  }, []);
  return (
    <Template>
      <PageHeader>
        <PageTitle>Estatus de carga de base de datos</PageTitle>
        <PageSubtitle>
          Ve y descarga el estatus de las cargas de bases de datos
        </PageSubtitle>
      </PageHeader>
      <Body />
    </Template>
  );
};

export default Management;
