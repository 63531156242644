import React, { useState } from "react";
import { useEffect } from "react";

const HomeHeader = () => {
    return (
        <div className="test_clase_home_div-color">
           Header
        </div>
    
    
    )
}

export default HomeHeader;