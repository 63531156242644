import React, { useState,
  createContext,
 } from "react";

export const InputToggleContext = createContext();

export default function InputToggle({ description, label, value, icon, children, disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(disabled);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <InputToggleContext.Provider value={{ handleOpen }}>
      {isOpen ? children : null}
      <div className={`container-fluid p-0 position-relative ${inputDisabled ? `pe-none opacity-50` : ``}`}>
        <div
          className={`position-absolute pe-none overflow-hidden input_label
                ${
                  value !== ""
                    ? "input_label_outside_input"
                    : "input_label_inside_input"
                }
                `}
        >
          {label}
        </div>
        <button
          type="button"
          style={{ overflowX: "hidden"}}
          className={`input_select  d-flex justify-content-start border border-dark`}
          onClick={handleOpen}
          disabled={inputDisabled}
        >
          <span
            className="overflow-hidden"
            style={{
              color: "inherit",
              fontSize: "inherit",
              fontWeight: "inherit",
              lineHeight: "inherit",
              textWrap: "nowrap"
            }}
          >
            {description}
          </span>
          {icon ?? (
            <div className="input_toggle_icon">
              <span>
              </span><span>
              </span><span>
              </span>
            </div>
          )}
        </button>
      </div>
    </InputToggleContext.Provider>
  );
}
