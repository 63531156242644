import React, { useState } from "react";
import InputCodeTwoFactor from "./InputCodeTwoFactor";

export default function TwoFactor({
  onIngresarButton = () => {},
  name,
  reenviarCodigo = () => {},
  codeValid,
}) {
  const [codigo, setCodigo] = useState("");
  const [reintentos, setReintentos] = useState(0);
  const [userName, setUserName] = useState("Juan");
  const limiteReintentos = 5;
  const validarInputCodigo = () => {
    return !codigo.includes("?") && /^[0-9]*$/.test(codigo);
  };
  const ingresar = () => {
    onIngresarButton(codigo);
  };

  return (
    <div className={`p-0 content_login_twofactor`}>
      {reintentos === 0 ? (
        <p className="card_text">
          {/* <label className="fw-bold">{name}</label>, hemos enviado un código a
          tu correo electrónico. Por favor, ingrésalo para validar tu identidad */}
          <label className="fw-bold">{name}</label>, hemos enviado un código a tu correo electrónico. Por favor, ingrésalo para validar tu identidad
        </p>
      ) : reintentos < limiteReintentos ? (
        <p>
          El código que has ingresado es incorrecto, favor de ingresarlo
          nuevamente. Intentos restantes {limiteReintentos - reintentos}
        </p>
      ) : (
        <p>
          Haz excedido el número de intentos máximos de ingreso para el código.
          Espera
          <bold className="fw-bold"> 15 minutos</bold> para realizar un nuevo
          intento
        </p>
      )}
      {/* <div className="d-flex justify-content-center container-fluid gap-3 my-4 login_two_factor_code"> */}
      <div className="d-flex container-fluid gap-3 my-4 login_two_factor_code">
        <InputCodeTwoFactor setCodeProps={setCodigo} validacionInput={codeValid}/>
      </div>
      <p className="no_send_code">
        ¿No recibiste ningún código?{" "}
        <a href="#" onClick={reenviarCodigo} style={{ color: "#1B80F2" }}>
          Reenviar
        </a>
      </p>
      <button
        onClick={ingresar}
        disabled={!validarInputCodigo()}
        className={`button ${
          validarInputCodigo() ? "button_active" : "button_disabled"
        }`}
        type="submit"
      >
        Ingresar
      </button>
    </div>
  );
}
