const availableValidation = {
  true: {
    color: "#609571",
    label: "Activo",
  },
  false: {
    color: "rgba(196, 60, 66, .8)",
    label: "Inactivo",
  },
};
export default function Validation({ validation }) {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "60px",
        color: "white",
        fontWeight: "bold",
        fontSize: "9px",
        background:
          availableValidation[validation.toString()]?.color ||
          availableValidation["Negativo"].color,
        padding: "4px 10px",
        marginLeft: "0px",
      }}
      className={
        "rounded-pill text-nowrap d-flex justify-content-center align-items-center " +
        (validation ? "validacion_label_true" : "validacion_label_false")
      }
    >
      {availableValidation[validation.toString()]?.label ||
        availableValidation["Negativo"].label}
    </div>
  );
}
