import React from "react";
import { Skeleton } from "@mui/material";

export default function ToSkeleton({ loading, style, children, ...props }) {
  return (
    <>
      {loading ? (
        <Skeleton
          {...(style ? {style} : { width: "100%" })}
          animation="wave"
          {...props}
        >
          {children}
        </Skeleton>
      ) : (
        children
      )}
    </>
  );
}
