import React, { useState } from "react";

const ModalCard = ({children}) => {
    return (
        <div className="modal_card_container complete_register_card bg-white">
            <div className="overflow-x-hidden mx-auto" style={{maxWidth:"280px"}}>
                {children}
            </div>
        </div>
    
    )
}

export default ModalCard;