import React, { useEffect, useState } from "react";
import { endpoints, loadEndpoints } from "../../services/api";
export default function Title({ children, type = "app", nameValue = "" }) {
  const [nombre, setNombre] = useState(
    endpoints?.nodo_name || process.env.REACT_APP_CONSTANT_NAME_NODO || ""
  );
  useEffect(() => {
    cargarEndpoint();
  }, [endpoints]);
  const cargarEndpoint = async () => {
    await loadEndpoints();
    setNombre(endpoints?.nodo_name || process.env.REACT_APP_CONSTANT_NAME_NODO);
  };
  return (
    <h4 className={"main_title"}>
      {children}
      <span style={{ color: "#2D2D2D80", marginLeft: "5px" }}>
        {type === "app" ? ` / ${nombre}` : ` / ${nameValue}`}
      </span>
    </h4>
  );
}
