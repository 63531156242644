import React, { useContext, useEffect, useState } from "react";
import SearchInput from "../Inputs/SearchInput";
import InputSelect from "../Inputs/InputSelect";
import Select from "../Users/Select";
import { NodeContext } from "./NodosComponent";

const useNodeContext = () => useContext(NodeContext);

export default function FilterForm({
  handleChange = () => {},
  handleSelectChange = () => {},
  values,
  handleRangeDateChange = (val) => {
    console.log(val);
  },
}) {
  const { nodeCatalog } = useNodeContext();

  return (
    <form
      className="container-fluid p-0 justify-content-start d-flex gap-1 gestion_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div style={{ maxWidth: "137px" }} className="container-fluid p-0 m-0">
        <SearchInput
          onChange={handleChange}
          value={values.search}
          label="Buscar"
          id="search"
          name="search"
          type="search"
          valid={true}
          rounded={true}
        />
      </div>
      <div style={{ maxWidth: "108px" }} className="container-fluid p-0 m-0">
        <Select
          id={`node`}
          name={`node`}
          label=""
          value={values?.node ?? ""}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          hide_current={true}
          options={[
            { nodo_id: "", name_select: "Seleccione una opción" },
            ...nodeCatalog,
          ]}
          valueKey="nodo_id"
          labelKey="name_select"
          rounded={true}
          arrowWidth={5}
          claseSpan="label_select_nodes"
        />
      </div>

      <button type="submit" className="d-none"></button>
    </form>
  );
}
