import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Loader from "../Loader/Loader";
import PageHeader from "../Pages/Header";
import PageTitle from "../Pages/Title";
import PageSubtitle from "../Pages/Subtitle";
import CopyCell from "./CopyCell";
import dayjs from "dayjs";

const Modal = ({ children }) => {
  return createPortal(
    <div className={`modal_container dco-details-transaccions-modal-container`}>
      {children}
    </div>,
    document.getElementById("root")
  );
};

export default function Details({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {isOpen && (
        <Modal>
          <div className="transactions_modal_details position-relative">
            <div
              style={{ marginBottom: "23px" }}
              className="container-fluid p-0 d-flex flex-wrap flex-md-nowrap"
            >
              <PageHeader type="modal">
                <PageTitle type="page" nameValue={data?.idNodo ?? ''}>Detalle de transacción</PageTitle>
                <PageSubtitle>
                  Ve el detalle de la transacción elegida
                </PageSubtitle>
              </PageHeader>
            </div>
            <div
              style={{ marginBottom: "14px" }}
              className="container-fluid p-0 d-flex flex-column shadow"
            >
              <div
                style={{
                  background: "#22135E",
                  padding: "13px 29px 13px 16px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
                className="container-fluid d-flex justify-content-between"
              >
                <p
                  style={{
                    fontSize: "10px",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                  className="m-0"
                >
                  Datos de la transacción
                </p>
              </div>
              <div
                className="bg-white container-fluid d-flex flex-wrap justify-content-between gap-1"
                style={{ padding: "13px 12px" }}
              >
                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-7 flex-wrap flex-md-nowrap">
                    <p
                      className="m-0 p-0 col-6"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"ID Transacción:"}
                    </p>
                    <div className="m-0 p-0 col-6">
                      <CopyCell padding={"0px"} text={data.idTransaccion} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-5">
                    <p
                      className="m-0 p-0 col-6"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Tiempo de respuesta:"}
                    </p>
                    <p
                      className="col-6 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {(parseInt(data.tiempo_respuesta) / 1000) ?? ""}
                      {" seg"}
                    </p>
                  </div>
                </div>
                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-7">
                    <p
                      className="m-0 p-0 col-6"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Fecha/Hora:"}
                    </p>
                    <p
                      className="col-6 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {dayjs
                        .utc(data.fecha_alta)
                        .utcOffset(-6)
                        .format("YYYY-MM-DD HH:mm:ss [(GMT-6)]") ?? ""}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-5">
                    <p
                      className="m-0 p-0 col-6"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Validación:"}
                    </p>
                    <p
                      className="col-6 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.validacion ?? ""}
                    </p>
                  </div>
                </div>
                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-7">
                    <p
                      className="m-0 p-0 col-6"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Índice de calidad de fotografía:"}
                    </p>
                    <p
                      className="col-6 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.IndiceCalidad_Fotografia ?? ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid p-0 d-flex flex-column shadow">
              <div
                style={{
                  background: "#22135E",
                  padding: "13px 29px 13px 16px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
                className="container-fluid d-flex justify-content-between"
              >
                <p
                  style={{
                    fontSize: "10px",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                  className="m-0"
                >
                  Nodos
                </p>
              </div>
              <div
                className="bg-white container-fluid d-flex flex-wrap justify-content-between gap-1"
                style={{ padding: "13px 12px" }}
              >
                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Considerados en validación:"}
                    </p>
                    <p
                      className="m-0 col-4"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_Considerados_Validacion ?? ""}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Existe registro:"}
                    </p>
                    <p
                      className="col-4 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_Existe_Registro ?? ""}
                    </p>
                  </div>
                </div>

                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Fuera de tiempo:"}
                    </p>
                    <p
                      className="m-0 col-4"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_Fuera_de_Tiempo ?? ""}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Error en validación:"}
                    </p>
                    <p
                      className="col-4 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_Error_Validacion ?? ""}
                    </p>
                  </div>
                </div>
                <div className="row p-0 m-0 container">
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"Considerados para Val ID Score:"}
                    </p>
                    <p
                      className="m-0 col-4"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_Considerados_ValIDScore ?? ""}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between container row m-0 p-0 col-12 col-md-6">
                    <p
                      className="m-0 p-0 col-8"
                      style={{ fontSize: "10px", fontWeight: "700" }}
                    >
                      {"No disponibles:"}
                    </p>
                    <p
                      className="col-4 m-0"
                      style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.Nodos_No_Disponibles ?? ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid p-0 d-flex flex-column shadow">
              <div
                style={{
                  background: "#22135E",
                  padding: "13px 29px 13px 16px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
                className="container-fluid d-flex justify-content-between"
              >
                <p
                  style={{
                    fontSize: "10px",
                    color: "#FFFFFF",
                    fontWeight: "700",
                  }}
                  className="m-0"
                >
                  Errores o excepciones
                </p>
              </div>
              <div
                className="bg-white container-fluid d-flex flex-wrap justify-content-between gap-1"
                style={{ padding: "13px 12px" }}
              >
                <ul className="row container ">
                  {data?.Error_excepcion_Transaccion.length > 0 ? 
                  <>
                    {data?.Error_excepcion_Transaccion.map((element) => (
                      <li style={{
                        fontSize: "10px",
                      }} key={element} className="col-12">{element}</li>
                    ))}
                  </> : <p className="m-0" style={{
                        fontSize: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                        paddingRight: "12px",
                        textOverflow: "ellipsis",
                      }}>Sin errores o excepciones.</p>}
                </ul>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="button button_active"
              style={{ marginTop: "15px" }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
      <button
        style={{ fontSize: "9px" }}
        className="management_see_details_button"
        onClick={() => setIsOpen(true)}
        type="button"
      >
        Ver detalle
      </button>
      {loading && <Loader />}
    </>
  );
}
