import React, { useState, useEffect, useCallback, useReducer } from 'react';
import InputNewPassword from '../Inputs/InputNewPassword';
import SelectorAvatar from './SelectorAvatar';
import AlertaGeneral from '../Alerts/AlertaGeneral';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import {
  completeRegistro,
  getCatalogoAreas,
  fileToBase64,
  getCatalogoPosicion,
  validateEmailLogin,
  login,
} from '../../services/api';
import DatePickerInput from '../Inputs/DatePickerInput';
import Select from '../Users/Select';
import { Permisos } from '../../services/permisos.js';
import ToSkeleton from '../Skeleton/ToSkeleton';
import ModalCard from '../Modal/ModalCard';

const availableModulesCatalog = {
  Usuario: '/usuarios',
  Ajustes: '/ajustes',
  Gestión: '/gestion',
  Actividad: '/actividad',
  Transacciones: '/transacciones',
  Dashboard: '/dashboard',
  Nodo: '/nodos',
};
/* Starts error reducer */

export const initialError = {
  errorQueue: [],
  currentError: null,
  timeLeft: 0,
};

export const errorReducer = (state, action) => {
  switch (action.type) {
    case 'increment': {
      return {
        ...state,
        errorQueue: [...state.errorQueue, action.message],

        currentError: state.currentError ? state.currentError : action.message,
        timeLeft: state.currentError ? state.timeLeft : 1500,
      };
    }
    case 'tick': {
      if (state.timeLeft <= 100) {
        const newQueue = state.errorQueue.slice(1);
        return {
          ...state,
          errorQueue: newQueue,
          currentError: newQueue.length > 0 ? newQueue[0] : null,
          timeLeft: newQueue.length > 0 ? 1500 : 0,
        };
      } else {
        return {
          ...state,
          timeLeft: state.timeLeft - 100,
        };
      }
    }
    default: {
      throw new Error('Unknown action: ' + action.type);
    }
  }
};

export const useErrorReducer = () => {
  const [errorValues, dispatchError] = useReducer(errorReducer, initialError);

  useEffect(() => {
    if (errorValues.currentError) {
      const interval = setInterval(() => {
        dispatchError({ type: 'tick' });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [errorValues.currentError]);

  const handleError = (message) => {
    dispatchError({
      type: 'increment',
      message,
    });
  };

  return {
    errorValues,
    handleError,
  };
};

/* Ends error reducer */


const CompletarRegistroForm = () => {
  const [userName, setUserName] = useState('');
  const [area, setArea] = useState('');
  const [puesto, setPuesto] = useState('');
  const [password, setPassword] = useState('');
  const [banderaPassword, setBanderaPassword] = useState('');
  const [selfie, setSelfie] = useState(null);
  const [fecha, setFecha] = useState('');
  const [areaCatalogo, setAreaCatalogo] = useState([]);
  const [posicionCatalogo, setPosicionCatalogo] = useState([]);
  const [positionSelectedCatalog, setPositionSelectedCatalog] = useState([]);
  const [userID, setUserID] = useState('');
  const [emailConfirmationToken, setEmailConfirmationToken] = useState('');
  const [tokenReset, setTokenReset] = useState('');
  const [extensionFile, setExtensionFile] = useState('png');
  const [infoUser, setInfoUser] = useState([]);
  const [error, setError] = useState(true);
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { errorValues, handleError } = useErrorReducer();
  const navigate = useNavigate();

  useEffect(() => {
    obtenerDatosUrl();
  }, []);

  const getAllData = useCallback(async (email) => {
    if (!navigator.onLine) {
      handleError('No hay conexión a Internet. Por favor, verifica tu conexión.')
      return;
    }
    setLoading(true);
    const [promisseUserInfo, promisseArea, promissePosition] =
      await Promise.allSettled([
        validateEmailLogin(email),
        getCatalogoAreas(),
        getCatalogoPosicion(),
      ]);
    if (promisseUserInfo.status === 'rejected') {
      handleError(
        'Ocurrió un error al consultar la información del usuario',
      );
    }
    if (promisseArea.status === 'rejected') {
      handleError('Ocurrió un error al consultar el catálogo de áreas');
    }

    if (promissePosition.status === 'rejected') {
      handleError(
        'Ocurrió un error al consultar el catálogo de posiciones',
      );
    }
    if (
      promisseUserInfo.status === 'fulfilled' &&
      !promisseUserInfo?.value?.success
    ) {
      handleError(
        promisseUserInfo?.value?.error_message ??
          'Lo sentimos ocurrió un error',
      );
    }

    if (
      promisseUserInfo.status === 'fulfilled' &&
      promisseUserInfo?.value?.success
    ) {
      setInfoUser(promisseUserInfo?.value?.data ?? {});
      setUserName(promisseUserInfo?.value?.data.firstName);
    }
    if (promisseArea.status === 'fulfilled' && promisseArea?.value?.data?.isSucceed) {
      setAreaCatalogo(promisseArea?.value?.data?.data ?? []);
    }
    if (
      promissePosition.status === 'fulfilled' &&
      promissePosition?.value?.data?.isSucceed
    ) {
      setPosicionCatalogo(promissePosition?.value?.data?.data ?? []);
    }
    if (
      promisseUserInfo.status === 'fulfilled' && promisseUserInfo?.value?.success &&
      promisseArea.status === 'fulfilled' && promisseArea?.value?.data?.isSucceed &&
      promissePosition.status === 'fulfilled' && promissePosition?.value?.data?.isSucceed
    ) {
      setError(false);
    }
    setLoading(false);
  }, [handleError]);

  useEffect(() => {
    if (area) {
      const selectedArea = posicionCatalogo.filter(
        (areaC) => areaC.areaId === area.id,
      );
      setPuesto('');
      setPositionSelectedCatalog(selectedArea ?? []);
    }
  }, [area, posicionCatalogo]);

  const validarDatos = () => {
    return (
      area &&
      puesto &&
      fecha &&
      selfie &&
      password &&
      (banderaPassword === 'strong' || banderaPassword === 'green')
    );
  };
  const obtenerDatosUrl = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error('NETWORK_CONNECTION');
      }
      let queryString = window.location.search.replaceAll('+', '%2B');
      let urlParams = new URLSearchParams(queryString);
      let userID = urlParams.get('UserID');
      let passwordResetToken = urlParams.get('PasswordResetToken');
      let emailConfirmationToken = urlParams.get('EmailConfirmationToken');
      let email = urlParams.get('Email');
      if (!(userID && passwordResetToken && emailConfirmationToken)) {
        handleError('Parámetros de URL inválidos.');
      }
      setUserID(userID);
      setTokenReset(passwordResetToken);
      setEmailConfirmationToken(emailConfirmationToken);
      getAllData(email);
      setEmail(email);
    } catch (error) {
      if (error.message === 'NETWORK_CONNECTION') {
        handleError(
          'No hay conexión a Internet. Por favor, verifica tu conexión.'
        );
        return;
      }
      console.log(error);
    }
  };
  const completarRegistro = async () => {
    try {
      if (!navigator.onLine) {
        handleError('No hay conexión a Internet. Por favor, verifica tu conexión.');
        return
      }
      setLoading(true);
      const profileImageBase64 = await fileToBase64(selfie);
      let regRes = await completeRegistro(
        userID,
        emailConfirmationToken,
        tokenReset,
        password,
        profileImageBase64,
        area.id,
        puesto.id,
        formatoFecha(fecha),
      );
      if (!regRes.isSucceed) {
        handleError(regRes.message);
        return
      }
      setSuccess(regRes.message);
      autoLogin();
    } catch (error) {
      console.log(error);
      handleError('Problemas al completar su registro.');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccess('');
      }, 5000);
    }
  };

  const inicializarPeticionPermisos = () => {
    let instancia = Permisos.getInstance();

    let prom = new Promise((resolver, rechazar) => {
      if (!instancia.datosCargados)
        instancia.updateDataFunc = (data) => {
          if (data.OK) resolver();
          else {
            rechazar('Error al obtener Privilegios');
          }
        };
      else resolver();
    });
    return prom;
  };

  const autoLogin = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error('NETWORK_CONNECTION');
      }
      let data = await validateEmailLogin(email);
      if (data.success) {
        sessionStorage.setItem(
          'lastPasswordChangedDate',
          data.data.lastPasswordChangedDate,
        );
      } else {
        handleError('Estamos teniendo problemas con el Inicio de Sesion');
      }
      const response = await login({
        email: infoUser.email,
        password: password,
      });
      if (!response.success) {
        handleError('Estamos teniendo problemas con el Inicio de Sesion');
        return;
      } else {
        sessionStorage.setItem('tokenType', response.data.tokenType);
        sessionStorage.setItem('accessToken', response.data.accessToken);
        sessionStorage.setItem('expiresIn', response.data.expiresIn);
        sessionStorage.setItem('refreshToken', response.data.refreshToken);
        sessionStorage.setItem('userEmail', infoUser.email);
        await inicializarPeticionPermisos();
        const availableModules = Permisos.obtenerModulosDisponibles();
        if (availableModules.includes('Dashboard')) {
          navigate('/dashboard');
          return;
        }
        if (availableModules.includes('Usuario')) {
          navigate('/usuarios');
          return;
        }
        navigate(availableModulesCatalog[availableModules[0]]);
        return;
      }
    } catch (error) {
      if (error.message === 'NETWORK_CONNECTION') {
        handleError(
          'No hay conexión a Internet. Por favor, verifica tu conexión.',
        );
        
        return;
      }
      handleError('Problemas al iniciar Sesion');
    }
  };
  const handleDatePickerChange = (values) => {
    let fecha = new Date(values.value);
    setFecha(fecha.toLocaleDateString('en-US'));
  };
  const formatoFecha = (date) => {
    let arrayFecha = date.split('/');
    let fechaFormat =
      (arrayFecha[2].length > 1 ? arrayFecha[2] : arrayFecha[2]) +
      '-' +
      (arrayFecha[0].length > 1 ? arrayFecha[0] : '0' + arrayFecha[0]) +
      '-' +
      (arrayFecha[1].length > 1 ? arrayFecha[1] : '0' + arrayFecha[1]);
    return fechaFormat;
  };

  return (
    <>
      <ModalCard>
        <div className='row m-0' style={{ minWidth: '100' }}>
          <div className='w-100 complete_register_container_template p-0'>
            <img
              src='/images/dicio/logo.webp'
              alt='DICIO  Alliance logo'
              className='w-100 h-auto'
            />
            <div className='d-flex flex-column align-items-start w-100'>
              <ToSkeleton loading={error}>
                <p
                  className='fw-bold '
                  style={{ marginBottom: '24px', lineHeight: '20.8px' }}
                >
                  Hola {userName}, captura los siguientes datos para activar tu
                  cuenta
                </p>
              </ToSkeleton>
              <div
                className='d-flex flex-column align-items-center justify-content-center w-100'
                style={{ gap: '7px' }}
              >
                <div
                  className='container d-flex flex-column align-items-start p-0'
                  style={{ marginBottom: '0px' }}
                >
                  <ToSkeleton loading={error}>
                    <p
                      className='input_label_small'
                      style={{ marginBottom: '6.8px', paddingLeft: '10px' }}
                    >
                      Selecciona un Avatar o sube tu fotografía
                    </p>
                  </ToSkeleton>

                  <ToSkeleton loading={error}>
                    <SelectorAvatar
                      setFlieProp={setSelfie}
                      setExtensionFileProp={setExtensionFile}
                    />
                  </ToSkeleton>
                </div>
                <div className='mt-0 container-fluid p-0 position-relative complete_register_date'>
                  <ToSkeleton loading={error}>
                    <DatePickerInput
                      onChange={handleDatePickerChange}
                      value={fecha}
                      label='Fecha de Nacimiento'
                      id='birdth_date'
                      valid={true}
                      rounded={false}
                    />
                  </ToSkeleton>
                </div>
                <div className='complete_register_input'>
                  <ToSkeleton loading={error}>
                    <Select
                      id={`area`}
                      name={`area`}
                      label='Área'
                      value={area?.id ?? ''}
                      handleSetValue={({ value }) => {
                        setArea(areaCatalogo.find(({ id }) => id === value));
                      }}
                      options={areaCatalogo}
                      valueKey='id'
                      labelKey='name'
                      rounded={false}
                      customStyles={{
                        border: '1px solid #000000',
                      }}
                    />
                  </ToSkeleton>

                  <ToSkeleton loading={error}>
                    <Select
                      id={`puesto`}
                      name={`puesto`}
                      label='Puesto'
                      value={puesto?.id ?? ''}
                      handleSetValue={({ value }) => {
                        setPuesto(
                          positionSelectedCatalog.find(
                            ({ id }) => id === value,
                          ),
                        );
                      }}
                      options={positionSelectedCatalog}
                      valueKey='id'
                      labelKey='name'
                      rounded={false}
                      customStyles={{
                        border: '1px solid #000000',
                      }}
                    />
                  </ToSkeleton>

                  <ToSkeleton loading={error}>
                    <InputNewPassword
                      setBanderaProp={setBanderaPassword}
                      setPasswordProp={setPassword}
                    />
                  </ToSkeleton>
                </div>
                <div
                  className='container p-0 flex justify-content-start'
                  style={{ marginTop: '34px', marginBottom: '0px' }}
                >
                  <button
                    className={`button ${
                      validarDatos() && !loading
                        ? 'button_active'
                        : 'button_disabled'
                    }`}
                    disabled={!validarDatos() || loading}
                    onClick={error ? () => {} : completarRegistro}
                  >
                    Activar cuenta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalCard>
      {errorValues.timeLeft > 0 && (
        <AlertaGeneral type={"error"}>{errorValues.currentError}</AlertaGeneral>
      )}
      {success && <AlertaGeneral type='success'>{success}</AlertaGeneral>}
      {loading && <Loader />}
    </>
  );
};

export default CompletarRegistroForm;
