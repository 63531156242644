import { useReducer, useCallback } from "react";

const INITIAL_PAGINATION = {
  page: 1,
  perPage: 10,
  totalElements: "",
  totalPages: 0,
  lastNumber: 0,
  firstNumber: 0,
  nextPage: 2,
  previousPage: 0,
  hasFirstNumber: false,
  hasLastNumber: false,
  hasPreviousPage: false,
  hasNextPage: false,
};

const handlePageInformation = ({ totalPages, page }) => {
  const hasPreviousPage = page === totalPages;
  const hasNextPage = page < totalPages - 1;
  return {
    lastNumber: !hasPreviousPage ? totalPages : null,
    nextPage: hasNextPage ? page + 1 : null,
    previousPage: hasPreviousPage ? page - 1 : null,
    hasPreviousPage,
    hasNextPage,
    firstNumber: !hasNextPage ? 1 : null,
    hasFirstNumber: !hasNextPage && totalPages > 3,
    hasLastNumber: !hasPreviousPage,
    totalPages,
    page
  };
};

const paginationReducer = (pagination, action) => {
  switch (action.type) {
    case "PAGE_CHANGE": {
      return {
        ...pagination,
        ...handlePageInformation({
          totalPages: action.totalPages,
          page: action.page,
        }),
      };
    }
    case "VALIDATE_TOTAL_PAGES": {
      return {
        ...pagination,
        ...(pagination.totalPages !== action.totalPages
          ? handlePageInformation({
              totalPages: action.totalPages,
              page: action.page,
            })
          : {}),
          totalElements: action.totalElements
      };
    }
    case "SET_VALUES": {
      return {
        ...action.values,
      };
    }
    case "SET_FIELD":
      return {
        ...pagination,
        [action.field]: action.value,
      };
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const usePaginationBack = () => {
  const [pagination, dispatchPagination] = useReducer(
    paginationReducer,
    INITIAL_PAGINATION
  );

  const handleResetPagination = () => {
    dispatchPagination({
      type: "SET_VALUES",
      values: INITIAL_PAGINATION,
    });
  };

  const handleSelectedPage = useCallback(
    (page) => {
      dispatchPagination({
        type: "PAGE_CHANGE",
        totalPages: pagination.totalPages,
        page,
      });
    },
    [pagination.totalPages]
  );

  const handleValidateTotalPages = useCallback(({totalPages, page, totalElements}) => {
    dispatchPagination({
      type: "VALIDATE_TOTAL_PAGES",
      totalPages,
      page,
      totalElements
    });
  }, []);

  const handleNextPage = useCallback(() => {
    if (pagination.page < pagination.totalPages)
      dispatchPagination({
        type: "PAGE_CHANGE",
        totalPages: pagination.totalPages,
        page: pagination.page + 1,
      });
  }, [pagination.page, pagination.totalPages]);

  const handlePreviousPage = useCallback(() => {
    if (pagination.page > 1)
      dispatchPagination({
        type: "PAGE_CHANGE",
        totalPages: pagination.totalPages,
        page: pagination.page - 1,
      });
  }, [pagination.page, pagination.totalPages]);

  return {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination
  };
};

export default usePaginationBack;
