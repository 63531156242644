import React, { useState, useEffect, useRef } from "react";
import { blobToB64 } from "../../services/data";
import AlertaGeneral from '../Alerts/AlertaGeneral';
const isUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
const SelectorAvatar = ({
  setFlieProp = () => {},
  setExtensionFileProp = () => {},
  value = "",
  newUser = false,
}) => {
  const [avatarSelected, setAvatarSelected] = useState("");
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [imagePrev, setImagePrev] = useState("");
  const [error, setError] = useState(null);
  const inputNewFile = useRef(null);
  const avatarURL = {
    a: "/images/complete_register/avatar_a.webp",
    b: "/images/complete_register/avatar_b.webp",
    c: "/images/complete_register/avatar_c.webp",
  };
  useEffect(() => {
    if (avatarSelected) obtenerArchivoAvatar(avatarSelected);
  }, [avatarSelected]);
  useEffect(() => {
    if (file) {
      setFlieProp(file);
    }
  }, [file]);

  const changeAvatar = (type) => {
    if (!navigator.onLine){
      setError("No hay conexión a Internet. Por favor, verifica tu conexión.");
      setTimeout(() => {
        setError(null);
      }, 1500)
      return;
    }
    setAvatarSelected(type);
    setExtensionFileProp("webp");
  };
  const nuevaImagenAvatar = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setAvatarSelected("file");
      let extension =
        e.target.files[0].name.split(".")[
          e.target.files[0].name.split(".").length - 1
        ];
      setExtensionFileProp(extension);
    }
  };
  const obtenerArchivoAvatar = async (avatar) => {
    try {
      if (avatar) {
        let url = avatarURL[avatar];
        if (url) {
          let res = await fetch(url);
          let imagen = await res.blob();
          setFile(imagen);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleProfileImageSelectedByUser = () => {
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setProfileImage(base64data);
      };
    };
    if (avatarSelected === "file") handleProfileImageSelectedByUser();
  }, [avatarSelected]);
  useEffect(() => {
    prevAvatarToB64();
  }, [value, profileImage]);

  const prevAvatarToB64 = async () => {
    try {
      let salida;
      if (value !== "") salida = value;
      else salida = profileImage;
      console.log(typeof salida);

      if (typeof salida !== "string")
        salida = (await blobToB64(salida)).split(",")[1] ?? "";
      setImagePrev(salida);
    } catch (error) {
      setImagePrev("");
    }
  };
  return (
    <>
    <div className="d-flex gap-2 container_register_container_avatar">
      <div className="d-flex gap-3 justify-content-between w-100">
        {avatarSelected === "file" || value ? (
          <img
            className={`rounded-circle ${
              avatarSelected === "file" || avatarSelected === ""
                ? "border border-success border-3"
                : ""
            } `}
            src={"data:image/*;base64," + imagePrev}
            alt={`Avatar user`}
            width={37}
            height={37}
          />
        ) : null}
        <div
          className="d-flex justify-content-center align-items-center position-relative avatar_input"
          onClick={() => {
            changeAvatar("a");
          }}
        >
          <img
            className={`rounded-circle  ${
              avatarSelected === "a" && "border border-success border-3"
            } `}
            src={avatarURL.a}
            alt="Avatar user profile2"
            width="37"
            height="37"
          />
          <input
            type="checkbox"
            className="rounded-circle position-absolute avatar_input start-0 top-0 w-100 h-100 opacity-0 rounded-circle z-3"
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center position-relative avatar_input"
          onClick={() => {
            changeAvatar("b");
          }}
        >
          <img
            className={`rounded-circle  ${
              avatarSelected === "b" && "border border-success border-3"
            } `}
            src={avatarURL.b}
            alt="Avatar user profile3"
            width="37"
            height="37"
          />
          <input
            type="checkbox"
            className="rounded-circle position-absolute avatar_input start-0 top-0 w-100 h-100 opacity-0 rounded-circle z-3"
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center position-relative avatar_input"
          onClick={() => {
            changeAvatar("c");
          }}
        >
          <img
            className={`rounded-circle  ${
              avatarSelected === "c" && "border border-success border-3"
            } `}
            src={avatarURL.c}
            alt="Avatar user profile3"
            width="37"
            height="37"
          />
          <input
            type="checkbox"
            className="rounded-circle position-absolute avatar_input start-0 top-0 w-100 h-100 opacity-0 rounded-circle z-3"
          />
        </div>
        <div
          className="w-auto input_file_profile avatar_input position-relative overflow-hidden"
          onClick={() => {
            inputNewFile.current.click();
          }}
        >
          <img
            src="/images/complete_register/plus.webp"
            width="37"
            height="37"
            className="avatar_input"
            alt="Plus avatar icon"
          />
          <input
            ref={inputNewFile}
            placeholder=""
            className="d-none position-absolute avatar_input top-50 start-50 translate-middle w-100 h-100 rounded-circle opacity-0"
            type="file"
            accept="image/*"
            onChange={nuevaImagenAvatar}
          />
        </div>
      </div>
    </div>
    {error && (
        <AlertaGeneral type='error'>{error}</AlertaGeneral>
      )}
    </>
    
  );
};

export default SelectorAvatar;
