import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
const TooltipPassword = ({
  x = 0,
  y = 0,
  validaciones = {
    min: false,
    oneUpperCase: false,
    oneLowerCase: false,
    noSecuence: false,
    noSpace: false,
    vacio: true,
    oneSpecialCaracter: false,
    minStrong: false,
  },
}) => {
  const [posicion, setPosicion] = useState([0, 0]);
  const containerGeneral = useRef(null);
  useEffect(() => {
    let altura = containerGeneral.current.getBoundingClientRect().height;
    setPosicion([x + 10, y - altura / 5]);
  }, [x, y]);

  return createPortal(
    <div
      ref={containerGeneral}
      className="d-flex flex-column bg-black p-2 tooltip_password_validator"
      tabindex="-1"
      id=":r2:"
      role="tooltip"
      style={{
        position: "absolute",
        left: "0px",
        top: "0px",
        transform: `translate(${posicion[0]}px, ${posicion[1]}px)`,
        willChange: "transform",
      }}
    >
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.min &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.min &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Longitud mínima de 12 caracteres
      </p>
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.oneUpperCase &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.oneUpperCase &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Al menos 1 letra mayúscula
      </p>
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.oneLowerCase &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.oneLowerCase &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Al menos 1 letra minúscula
      </p>
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.noSecuence &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.noSecuence &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Evitar secuencias de más de 3 caracteres (123, abc)
      </p>
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Evitar información personal como nombre
      </p>
      <p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.noSpace &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.noSpace &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;No puede empezar o terminar con un espacio en blanco
      </p>
      {/*<p
        className={`
                ${validaciones.vacio && "password_requirements_tooltip_text"}
                ${
                  !validaciones.vacio &&
                  validaciones.oneSpecialCaracter &&
                  "password_requirements_tooltip_text_valid"
                } 
                ${
                  !validaciones.vacio &&
                  !validaciones.oneSpecialCaracter &&
                  "password_requirements_tooltip_text_error"
                }
                `}
      >
        ○ &nbsp;Al menos 1 caracter especial ($+#-_)
      </p>*/}
    </div>,
    document.body
  );
};

export default TooltipPassword;
