import { Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { refreshToken } from "../../services/api";
import { Permisos } from "../../services/permisos.js";

const hanldeRefreshToken = async ({ service, navigate, setter, reset }) => {
  try {
    const response = await service();
    if (!response) {
      sessionStorage.clear();
      Permisos.destroy();
      navigate("/login");
      console.error("Error request refreshing token");
      return;
    }
    if (response.status === 401) {
      sessionStorage.clear();
      Permisos.destroy();
      navigate("/login");
      console.error("Error credentials");
      return;
    }
    sessionStorage.setItem("tokenType", response.tokenType);
    sessionStorage.setItem("accessToken", response.accessToken);
    sessionStorage.setItem("expiresIn", response.expiresIn);
    sessionStorage.setItem("refreshToken", response.refreshToken);
    setter(response.expiresIn);
    reset();
    return;
  } catch (error) {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
    console.error("Error credentials");
    return;
  }
};

const usePrivateRoutes = () => {
  const [expireIn, setExpireIn] = useState(null);
  const [active, setActive] = useState(true);
  const navigate = useNavigate();

  const handleOnIdle = () => {
    setActive(false);
    sessionStorage.clear();
    Permisos.destroy();
    console.log("Finalizó sesión por inactividad");
    navigate("/login");
  };

  const { reset } = useIdleTimer({
    timeout: 1800000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const handleReset = () => {
    reset();
    setActive(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (active) {
        hanldeRefreshToken({
          service: refreshToken,
          navigate,
          setter: setExpireIn,
          reset: handleReset,
        });
      } else {
        sessionStorage.clear();
        Permisos.destroy();
        console.log("Expiró la sesión");
        navigate("/login");
      }
    }, 300000);
    //
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    const expiresInSession = sessionStorage.getItem("expiresIn");

    if (!accessToken) {
      sessionStorage.clear();
      Permisos.destroy();
      navigate("/login");
      return;
    }
    setExpireIn(parseInt(expiresInSession, 10));
  }, [navigate]);

  return {
    active,
  };
};

const PrivateRoutes = () => {
  const { active } = usePrivateRoutes();
  return active ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
