import React from "react";

export default function ArrorPreviewPagination() {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8351 7.8472C8.61524 8.05093 8.25873 8.05093 8.03887 7.8472L4.28534 4.36892C4.06548 4.16518 4.06548 3.83482 4.28534 3.63108L8.03887 0.152804C8.25873 -0.0509349 8.61524 -0.0509348 8.8351 0.152804C9.05497 0.356595 9.05497 0.686908 8.8351 0.890647L5.47974 4L8.8351 7.10935C9.05496 7.31309 9.05496 7.64346 8.8351 7.8472Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8351 7.8472C4.61524 8.05093 4.25873 8.05093 4.03887 7.8472L0.285337 4.36892C0.065475 4.16518 0.0654751 3.83482 0.285337 3.63108L4.03887 0.152804C4.25873 -0.0509349 4.61524 -0.0509348 4.8351 0.152804C5.05497 0.356595 5.05497 0.686908 4.8351 0.890647L1.47974 4L4.8351 7.10935C5.05496 7.31309 5.05496 7.64346 4.8351 7.8472Z"
        fill="inherit"
      />
    </svg>
  );
}
