import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import Body from "../components/Transactions/Body";
import { useNavigate } from "react-router-dom";
import { Permisos } from "../services/permisos";

const Transactions = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Transacciones", "Ver la sección")) cerrarSesion();
  }, [])
  
  return (
    <Template>
      <PageHeader>
        <PageTitle>Estatus de transacciones</PageTitle>
        <PageSubtitle>
          Ve el estatus actual de las transacciones del nodo
        </PageSubtitle>
      </PageHeader>
      <Body />
    </Template>
  );
};

export default Transactions;
