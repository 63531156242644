import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import Body from "../components/Actividad/Body";
import { Permisos } from "../services/permisos";
import { useNavigate } from "react-router-dom";

const Actividad = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Bitácora", "Ver la sección"))
      cerrarSesion();
  }, []);
  return (
    <Template>
      <PageHeader>
        <PageTitle>Registro de eventos</PageTitle>
        <PageSubtitle>Ve el registro de actividad en el sistema</PageSubtitle>
      </PageHeader>
      <Body />
    </Template>
  );
};

export default Actividad;
