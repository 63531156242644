import React, { useState } from "react";
import PhoneValidation from "./PhoneValidation";

export default function InputPhone({
  onChange,
  value,
  label,
  id,
  name,
  type,
  valid,
  rounded,
  tooltip
}) {
  const [focused, setFocused] = useState(false);
  const inputError = !valid && value !== "";
  const handleFocus = () => {
    setFocused(!focused);
  };
  return (
    <div className="position-relative mt-3 container-fluid p-0">
      <div
        className={`position-absolute pe-none 
        ${rounded ? "input_label_small" : "input_label" }
        ${
          inputError
            ? `text-danger`
            : ``
        }
      ${
        !(focused || value !== "")
          ? "input_label_inside_input_phone"
          : "input_label_outside_input_phone"
      }
      `}
      >
        {label}
      </div>
      <div className={`input_phone_container input-group border ${
            inputError
              ? `border-danger text-danger`
              : `border-dark`
          }`}>
        <span className={`input-group-text 
          phone_input_label 
          bg-transparent 
          border 
          border-0 
          position-relative
          fw-bold
          ${
            inputError
              ? `phone_input_label_error text-danger`
              : `phone_input_label_neutral`
          }
        `} id={`label-${id}`}>+52</span>
        <input 
         autoComplete="off"
         id={id}
         name={name}
         type={type}
         onFocus={handleFocus}
         onBlur={handleFocus}
         onChange={onChange}
         value={value}
         maxLength={10}
         className={`input_phone form-control bg-transparent
         ${
          inputError
            ? `text-danger`
            : ``
        }
          ${rounded ? "rounded-pill" : ""} `}
         aria-describedby={`label-${id}`} />
      </div>
      {tooltip &&
        <PhoneValidation valid={valid} />
      }
    </div>
  );
}
