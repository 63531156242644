import React, { useContext } from "react";
import SearchInput from "../Inputs/SearchInput";
import DatePickerInput from "../Inputs/DatePickerInput";
import ClockIcon from "../Sharing/ClockIcon";
import Select from "../Users/Select";
import { ActivityContext } from "./Body";
import dayjs from "dayjs";

const useActivityContext = () => useContext(ActivityContext);

const daysOptions = [
  {
    label: "Últimas 24hrs",
    value: "last_24_hrs",
  },
  {
    label: "Esta semana",
    value: "this_week",
  },
  {
    label: "Última semana",
    value: "last_week",
  },
  {
    label: "Hoy",
    value: "today",
  },
  {
    label: "Últimos 90 días",
    value: "last_90_days",
  },
  /*{
    label: "Este año",
    value: "this_year",
  },
  {
    label: "Últimos 12 meses",
    value: "last_12_months",
  },*/
];

const hoursOptions = [
  {
    label: "00:00",
    value: "00:00",
  },
  {
    label: "01:00",
    value: "01:00",
  },
  {
    label: "02:00",
    value: "02:00",
  },
  {
    label: "03:00",
    value: "03:00",
  },
  {
    label: "04:00",
    value: "04:00",
  },
  {
    label: "05:00",
    value: "05:00",
  },
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "20:00",
    value: "20:00",
  },
  {
    label: "21:00",
    value: "21:00",
  },
  {
    label: "22:00",
    value: "22:00",
  },
  {
    label: "23:00",
    value: "23:00",
  },
  {
    label: "23:59",
    value: "23:59",
  },
];

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  handleSelectChange,
  values,
  handleDaysChange,
}) {
  const { eventLogsOptions } = useActivityContext();

  return (
    <form
      className="container-fluid justify-content-start d-flex gap-1 transactrions_filter_form"
      style={{ padding: "0 5px 0 0" }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div style={{ maxWidth: "137px" }} className="container-fluid p-0 m-0">
        <SearchInput
          onChange={handleChange}
          value={values.search}
          label="Buscar"
          id="search"
          name="search"
          type="search"
          valid={true}
          rounded={true}
        />
      </div>
      <div style={{ maxWidth: "96px" }} className="container-fluid p-0 m-0">
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.start_date}
          label="Desde"
          id="start_date"
          valid={true}
          rounded={true}
          minDate={dayjs().subtract(90, "day")}
        />
      </div>
      <div style={{ maxWidth: "75px" }} className="container-fluid p-0 m-0">
        <Select
          id={`start_hours`}
          name={`start_hours`}
          label=""
          value={values.start_hours}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={hoursOptions}
          valueKey="value"
          labelKey="label"
          arrow="white"
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      <div style={{ maxWidth: "96px" }} className="container-fluid p-0 m-0">
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.end_date}
          label="Hasta"
          id="end_date"
          valid={true}
          rounded={true}
          minDate={dayjs().subtract(90, "day")}
        />
      </div>
      <div style={{ maxWidth: "75px" }} className="container-fluid p-0 m-0">
        <Select
          id={`end_hours`}
          name={`end_hours`}
          label=""
          value={values.end_hours}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={hoursOptions}
          valueKey="value"
          labelKey="label"
          arrow="white"
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      <div style={{ maxWidth: "52px" }} className="container-fluid p-0 m-0 ">
        <Select
          id={`days`}
          name={`days`}
          label=""
          value={values.days}
          handleSetValue={handleDaysChange}
          options={daysOptions}
          valueKey="value"
          labelKey="label"
          rounded={true}
          max_width={52}
          arrowWidth={5}
        />
      </div>
      <div style={{ maxWidth: "151px" }} className="container-fluid p-0 m-0 ">
        <Select
          id={`type_event`}
          name={`type_event`}
          label="Tipo de evento registrado"
          value={values.type_event}
          handleSetValue={handleDaysChange}
          options={eventLogsOptions}
          valueKey="id"
          labelKey="level"
          rounded={true}
          max_width={151}
          arrowWidth={5}
        />
      </div>
      <button type="submit" className="d-none"></button>
    </form>
  );
}
