import React, {
    useState,
    useRef,
} from "react";
import Checkbox from "../Inputs/Checkbox";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
// import Loader from "../Loader/Loader";

export default function ExportData(
    {
        exportarGraficasImagen,
        setLoading,
    }
) {
    const modalRef = useRef(null);
    const [typeAlert, setTypeAlert] = useState('success')
    const [showAlert, setShowAlert] = useState(false);
    const [checkPDF, setCheckPDF] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);

    const handleExportButton = async (e) => {
        e.preventDefault();

        if (!navigator.onLine) {
            setAlertMessage('No hay conexión a Internet. Por favor, verifica tu conexión.');
            setTypeAlert('error');
            setShowAlert(true)
            setTimeout(() => {
                setTypeAlert('')
                setShowAlert(false);
                setAlertMessage("");
                setLoading(false);
            }, 2000);
        } else {
            setLoading(true);
            if (!checkPDF) {
                setShowAlert(true);
                setTypeAlert('error')
                setAlertMessage("Por favor seleccione un formato de exportación");
                setTimeout(() => {
                    setTypeAlert('')
                    setShowAlert(false);
                    setAlertMessage("");
                    setLoading(false);
                }, 2000);
                return;
            }
            // aquí se ejecuta la función de descarga PDF
            setTimeout(() => {
                exportarGraficasImagen()
                handleModal()
            }, 2000);
        }
    };

    const handleModal = () => {
        modalRef?.current?.handleOpenModal();
    };

    return (
        <>
            <Modal ref={modalRef}>
                <div className={"user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_transactions"}>
                    <div className="container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title">
                        <h4 className="main_title">Estatus de transacciones</h4>
                        <p className="main_subtitle">Ve el estatus actual de las transacciones</p>
                    </div>
                    <div className="export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column">
                        <p className="card_title" style={{ marginBottom: "25px" }}>
                            Exportación de información
                        </p>
                        <p className="card_text">
                            Se exportará la información seleccionada en los siguientes
                            formatos:
                        </p>
                        <form onSubmit={handleExportButton} className="container p-0">
                            <div className="container-fluid p-0 d-flex gap-3">
                                <div className="">
                                    <Checkbox
                                        id="pdf"
                                        name="pdf"
                                        label="PDF"
                                        rounded={true}
                                        onChange={() => setCheckPDF(!checkPDF)}
                                        value={checkPDF}
                                    />
                                </div>
                            </div>
                            <div
                                style={{ marginTop: "30px" }}
                                className={"container-fluid p-0 d-flex gap-3"}
                            >
                                <button className={`button ${!checkPDF ? 'button_disabled' : 'button_active'}`} type="submit" disabled={!checkPDF}>
                                    Exportar
                                </button>
                                <button
                                    type="button"
                                    onClick={handleModal}
                                    className="cancel_button"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <button
                type="button"
                onClick={handleModal}
                className="export_button d-flex gap-1 justify-content-center align-items-center"
            >
                Exportar
            </button>
            {/* {loading && <Loader />} */}
            {showAlert && <AlertaGeneral type={typeAlert}>{alertMessage}</AlertaGeneral>}
        </>
    );
}
