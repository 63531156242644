import React, { useState } from "react";
import { useEffect } from "react";
import HomeHeader from "../components/Home/HomHeader";

const Home = () => {
    return (
        <div>
            <HomeHeader/>
            Test Home 
        </div>
    
    
    )
}

export default Home;