import React from "react";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import ContainerAjustes from "../components/Ajustes/ContainerAjustes";

const Ajustes = () => {
  return (
    <Template>
      <PageHeader>
        <PageTitle>Ajustes y seguridad de la cuenta</PageTitle>
        <PageSubtitle>
          Ve y administra la configuración de tu cuenta
        </PageSubtitle>
      </PageHeader>
      <ContainerAjustes />
    </Template>
  );
};

export default Ajustes;
