import { useState, useEffect } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import { createTheme } from "@mui/material/styles";
import dayjs from "dayjs";

const useDatePicker = ({ field }) => {
  const [focused, setFocused] = useState(false);
  const [fieldPicker, setFieldPicker] = useState(false);
  // const maxDate = dayjs();

  const handleFocus = () => {
    setFocused(!focused);
  };

  const handleValue = (value) => {
    return value !== "" ? dayjs(value) : null;
  };

  const handlerOfChanges = ({ value, handleFormik }) => {
    handleFormik({
      field: fieldPicker,
      value:
        dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
          ? dayjs(value).format("YYYY-MM-DD")
          : "",
    });
  };

  const slotProps = {
    field: { onBlur: handleFocus, onFocus: handleFocus },
  };

  useEffect(() => {
    setFieldPicker(field);
  }, [field]);

  const newTheme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#bbdefb",
            borderRadius: "8px",
            border: "none",
            backgroundColor: "#FFFFFF",
            maxWidth: "200px",
            maxHeight: "172px",
          },
        },
      },
    },
  });
  return {
    handleValue,
    handlerOfChanges,
    focused,
    slotProps,
    newTheme,
    // maxDate,
  };
};

export default function DatePickerInput({
  onChange,
  value,
  label,
  id,
  valid,
  rounded,
  minDate,
  maxDate
}) {
  const { handleValue, focused, slotProps, newTheme } = useDatePicker({
    field: id,
  });

  const inputError = !valid && value !== "";

  return (
    <div className="mt-3 container-fluid p-0 position-relative">
      <div
        className={`position-absolute pe-none 
        ${rounded ? "input_label_small" : "input_label" }
      ${
        !(focused || value !== "")
          ? "input_label_inside_input"
          : "input_label_outside_input"
      }
      `}
      >
        {label}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <ThemeProvider theme={newTheme}>
          <DesktopDatePicker
            slotProps={slotProps}
            className={` ${
              rounded
                ? "input_date_picker_small rounded-pill"
                : "input_date_picker"
            }  ${
              inputError
                ? `border-danger`
                : rounded
                ? "border_for_small_inputs"
                : `border-black`
            }`}
            value={handleValue(value)}
            minDate={minDate}
            maxDate={maxDate ?? dayjs()}
            inputFormat="YYYY-MM-DD"
            onChange={(newValue) => {
              onChange({
                value: newValue,
                name: id,
              });
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}
