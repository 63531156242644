import React, {
  useState,
  useReducer,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import TableActions from "./TableActions";
import UserStatus from "./UserStatus";
import UserProfilePhoto from "./UserProfilePhoto";
import { getAllUsersService } from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import CreateUser from "./CreateUser";
import { handleGetCatalogs, toUserTimeZone } from "../../services/data";
import { getCatalogs, exportUsersService } from "../../services/api";
import ExportData from "./ExportData";
import CreateRol from "./CreateRol";
import ToAccordion from "../Accordion/ToAccordion";
import { Permisos } from "../../services/permisos";
import { formatDateToGMT06 } from "../../services/data.js";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";

export const UserContext = createContext();

export const handleExportUsers = async ({
  values,
  service,
  loading,
  error,
  error_message,
}) => {
  try {
    loading();
    const response = await service(values);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, 1500);
      console.error("Error request");
      return;
    }
    loading();
    return response.data;
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const INITIAL_VALUES = {
  search: "",
  status: "",
  register_date: "",
  rol: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const mappingOrderKeys = {
  nombre: "Nombre",
  apellidoPaterno: "ApellidoPaterno",
  apellidoMaterno: "ApellidoMaterno",
  email: "Correo",
  fechaAlta: "Estatus",
  status: "FechaAlta",
  puesto: "Puesto",
};

export const handleGetAllUsers = async ({
  values,
  service,
  loading,
  error,
  errorVal,
  error_message,
  handleValidateTotalPages,
  setTableData,
  signal,
}) => {
  try {
    if (signal.current) {
      signal.current.abort();
    }
    signal.current = new AbortController();
    loading(true);
    const response = await service({
      ...values,
      signal: signal.current.signal,
    });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
      } else {
        loading(false);
        if (!errorVal) {
          error(true);
          error_message(response.message);
          setTimeout(() => {
            error(false);
            error_message("");
          }, 1500);
        }
      }
      console.error("Error request");
      return;
    }
    loading(false);
    handleValidateTotalPages({
      totalPages: response.pagination.TotalPage,
      page: response.pagination.CurrentPage,
      totalElements: response.pagination.TotalCount,
    });
    setTableData(response.data);
  } catch (err) {
    loading(false);
    error(true);
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error(false);
      error_message("");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  }
};

export default function Body() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshRol, setRefreshRol] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [rolOptions, setRolOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const [orderConfig, setOrderConfig] = useState({
    direction: "asc",
    key: "nombre",
  });
  const abortControllerRef = useRef(null);

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  // Handlers
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const getUsers = async () => {
    handleGetAllUsers({
      values: {
        current_page: pagination.page,
        limit: true,
        OrderAscending: orderConfig.direction,
        OrderBy: mappingOrderKeys[orderConfig.key],
        ...values,
      },
      loading: setLoading,
      error: setError,
      errorVal: error,
      error_message: (msj) => handleErrorMessage(msj),
      handleValidateTotalPages,
      setTableData,
      service: getAllUsersService,
      signal: abortControllerRef,
    });
  };

  const handleRefreshData = () => {
    setRefreshData((prevState) => !prevState);
  };

  const handleRefreshRol = () => {
    setRefreshRol((prevState) => !prevState);
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleResetPagination();
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSelectChange = (values) => {
    handleResetPagination();
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleDatePickerChange = (newValue) => {
    handleResetPagination();
    const { name, value } = newValue;
    const valueSet =
      dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(value).format("YYYY-MM-DD")
        : "";
    setFieldValue(name, valueSet);
  };

  const handleExport = async () => {
    const data = await handleExportUsers({
      values: {
        limit: false,
        ...values,
      },
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      service: getAllUsersService,
    });
    return data;
  };

  const handleDownloadPdf = async () => {
    const data = await handleExportUsers({
      values,
      loading: () => {},
      error: () => handleError(),
      error_message: () => {},
      service: exportUsersService,
    });
    return data;
  };

  // Effects
  useEffect(() => {
    getUsers();
  }, [
    refreshData,
    values?.status,
    values?.register_date,
    values?.rol,
    pagination.page,
    orderConfig,
  ]);

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setStatusOptions,
      catalog: "status",
    });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setRolOptions,
      catalog: "rol",
    });
  }, [refreshRol]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Avatar / Foto",
        custom_cell: ({ user }) => <UserProfilePhoto src={user.imagenPerfil} />,
        className: "table_header_cell",
        style: {
          minWidth: "99px",
          maxWidth: "99px",
        },
        cell_styles: {
          minWidth: "99px",
          maxWidth: "99px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Nombre(s)",
        selector: ({ user }) => user.nombre,
        key_name: "nombre",
        service: handleOrderByColumns,
        className: "table_header_cell",
        style: {
          minWidth: "94px",
          maxWidth: "94px",
        },
        cell_styles: {
          minWidth: "94px",
          maxWidth: "94px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Apellido Paterno",
        selector: ({ user }) => user.apellidoPaterno,
        className: "table_header_cell",
        key_name: "apellidoPaterno",
        service: handleOrderByColumns,
        style: {
          minWidth: "119px",
          maxWidth: "119px",
        },
        cell_styles: {
          minWidth: "119px",
          maxWidth: "119px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Apellido Materno",
        selector: ({ user }) => user.apellidoMaterno,
        className: "table_header_cell",
        key_name: "apellidoMaterno",
        service: handleOrderByColumns,
        style: {
          minWidth: "115px",
          maxWidth: "115px",
        },
        cell_styles: {
          minWidth: "115px",
          maxWidth: "115px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Correo",
        selector: ({ user }) => user.email,
        className: "table_header_cell",
        key_name: "email",
        service: handleOrderByColumns,
        style: {
          minWidth: "112px",
          maxWidth: "112px",
        },
        cell_styles: {
          minWidth: "112px",
          maxWidth: "112px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Puesto",
        selector: ({ user }) => user.puesto,
        className: "table_header_cell",
        key_name: "puesto",
        style: {
          minWidth: "99px",
          maxWidth: "99px",
        },
        cell_styles: {
          minWidth: "99px",
          maxWidth: "99px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Roles",
        selector: ({ roles }) => roles[0]?.name ?? "",
        className: "table_header_cell",
        style: {
          minWidth: "84px",
          maxWidth: "84px",
        },
        cell_styles: {
          minWidth: "84px",
          maxWidth: "84px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Fecha de alta",
        selector: ({ user }) =>
          toUserTimeZone(formatDateToGMT06(user.fechaAlta)).format(
            "YYYY-MM-DD"
          ),
        className: "table_header_cell",
        key_name: "fechaAlta",
        service: handleOrderByColumns,
        style: {
          minWidth: "101px",
          maxWidth: "101px",
        },
        cell_styles: {
          minWidth: "101px",
          maxWidth: "101px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Estatus",
        custom_cell: ({ user, index }) => (
          <div
            className={
              "w-100 p-0 m-0 " +
              (Permisos.verificarPermiso("Usuario", "Cambiar Estatus")
                ? ""
                : "pe-none")
            }
          >
            <UserStatus
              status={user.status}
              id={user.id}
              name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
              index={index}
            />
          </div>
        ),
        selector: ({ user }) => user.status,
        className: "table_header_cell",
        key_name: "status",
        service: handleOrderByColumns,
        style: {
          minWidth: "100px",
          maxWidth: "100px",
        },
        cell_styles: {
          minWidth: "100px",
          maxWidth: "100px",
          overflow: "visible",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Acciones",
        custom_cell: ({ user }) => (
          <TableActions
            id={user.id}
            name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
            status={user.status}
          />
        ),
        className: "table_header_cell",
        style: {
          minWidth: "90px",
          maxWidth: "90px",
        },
        cell_styles: {
          minWidth: "90px",
          maxWidth: "90px",
          padding: "10px 9px 10px 0px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
  ]);

  return (
    <UserContext.Provider
      value={{
        handleRefreshData,
        handleExport,
        statusOptions,
        rolOptions,
        handleRefreshRol,
        handleDownloadPdf,
      }}
    >
      <div
        className="container-fluid d-flex flex-column p-0 module_users"
        style={{ position: "relative" }}
      >
        {Permisos.verificarPermiso("Usuario", "Buscar") && (
          <div
            style={{ marginBottom: "11px" }}
            className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
          >
            <ToAccordion breackpoint={1030} icon={null} label="Filtros">
              {Permisos.verificarPermiso("Usuario", "Ver la sección") && (
                <FilterForm
                  handleDatePickerChange={handleDatePickerChange}
                  handleChange={handleChange}
                  handleSelectChange={handleSelectChange}
                  values={values}
                />
              )}
            </ToAccordion>
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap mt-3">
              {Permisos.verificarPermiso("Usuario", "Crear Rol") && (
                <CreateRol />
              )}
              {Permisos.verificarPermiso("Usuario", "Crear usuario") && (
                <CreateUser />
              )}
              {Permisos.verificarPermiso("Usuario", "Exportar") && (
                <ExportData />
              )}
            </div>
          </div>
        )}
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
    </UserContext.Provider>
  );
}
