import React, { useContext } from 'react';
import SearchInput from '../Inputs/SearchInput';
import Select from './Select';
import DatePickerInput from '../Inputs/DatePickerInput';
import InputSelect from '../Inputs/InputSelect';
import { UserContext } from './Body';

const useUserContext = () => useContext(UserContext);

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  handleSelectChange,
  values,
}) {
  const { statusOptions, rolOptions, handleRefreshData } = useUserContext();
  return (
    <form
      className='container p-0 justify-content-start d-flex gap-1 user_filter_form'
      onSubmit={(e) => {
        e.preventDefault();
        handleRefreshData();
      }}
    >
      <div style={{ maxWidth: '206px' }} className='container p-0 m-0'>
        <SearchInput
          onChange={handleChange}
          value={values.search}
          label='Buscar'
          id='search'
          name='search'
          type='search'
          valid={true}
          rounded={true}
        />
      </div>
      <div style={{ maxWidth: '68px' }} className='container p-0 m-0'>
        <Select
          id={`status`}
          name={`status`}
          label='Estatus'
          value={values.status}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={statusOptions}
          valueKey='name'
          labelKey='name'
          arrowWidth={5}
          rounded={true}
        />
      </div>
      <div style={{ maxWidth: '98px' }} className='container p-0 m-0'>
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.register_date}
          label='Fecha de alta'
          id='register_date'
          valid={true}
          rounded={true}
        />
      </div>
      <div style={{ maxWidth: '59px' }} className='container p-0 m-0'>
        <Select
          id={`rol`}
          name={`rol`}
          label='Roles'
          value={values.rol}
          handleSetValue={({ field, value }) => {
            handleSelectChange({
              name: field,
              value,
            });
          }}
          options={rolOptions.reverse()}
          valueKey='name'
          labelKey='name'
          arrowWidth={5}
          rounded={true}
        />
      </div>
      <button type='submit' className='d-none'></button>
    </form>
  );
}
