import React, { useState } from "react";
import Help from "../components/Modal/Help";
import { useNavigate } from "react-router-dom";

const BloqueoUsuario = () => {
  const navigate = useNavigate();
  return (
    <div
      className={`vh-100 container-fluid d-flex justify-content-center p-3 align-items-center`}
    >
      <div
        className={`container-fluid bg-white d-flex flex-column justify-content-between align-items-center`}
        style={{
          width: "435px",
          height: "322px",
          borderRadius: "5px",
          paddingInline: "80px",
          paddingBottom: "30px",
          paddingTop: "50px",
        }}
      >
        <div
          style={{
            color: "#22135E",
            fontWeight: "700",
            fontSize: "22px",
            width: "100%",
          }}
        >
          {" "}
          Bloqueo de cuenta
        </div>
        <div>
          <p>
            Haz excedido el número de intentos máximos de ingreso para el
            código. Por seguridad, se ha realizado un bloqueo a tu cuenta
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="button button_active"
          >
            Cerrar
          </button>
        </div>
        <Help marginTop={20} />
      </div>
    </div>
  );
};

export default BloqueoUsuario;
