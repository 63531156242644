import React, {
  useState,
  useReducer,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import Table from "../Table/Container";
import {
  exportBitacoraPDF,
  exportBitacoraService,
  getAllLogsService,
} from "../../services/api";
import ArrorPreviewPagination from "../Table/ArrorPreviewPagination";
import ArrorNextPagination from "../Table/ArrorNextPagination";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import { createDatePickerChangeHandler, getEndDateAndTime } from "../../services/data";
import { handleGetCatalogs, useErrorReducer } from "../../services/data";
import { getCatalogs } from "../../services/api";
import ExportData from "./ExportData";
import FilterForm from "./FilterForm";
import ToAccordion from "../Accordion/ToAccordion";
import { Permisos } from "../../services/permisos";
import { saveAs } from "file-saver";

export const ActivityContext = createContext();

const formatHour = (hour) => {
  if (hour < 0 || hour > 23) {
    //throw new Error("Hour must be between 0 and 23");
    return "23:59";
  }
  const formattedHour = hour.toString().padStart(2, "0");
  return `${formattedHour}:00`;
};
export const handleExportActivity = async ({
  values,
  service,
  loading,
  error,
  error_message,
  signal,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    loading();
    const response = await service({ ...values, signal });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
        return;
      }
      loading();
      error("increment", response.message);
      setTimeout(() => {
        error("decrement", response.message);
        error_message("");
      }, 1500);
      console.error("Error request");
      return;
    }
    loading();
    return response.data;
  } catch (err) {
    loading();
    if (error.message === "NETWORK_CONNECTION") {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, 1500);
      return;
    }
    error("increment", err.message);
    setTimeout(() => {
      error("decrement", "");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  }
};
export const handleGetAllLogs = async ({
  values,
  service,
  loading,
  error,
  error_message,
  handleSetPagination,
  setTableData,
  signal,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    if (signal.current) {
      signal.current.abort();
    }
    signal.current = new AbortController();
    loading();
    const response = await service({
      ...values,
      signal: signal.current.signal,
    });
    if (!response.success) {
      if (response.name === "AbortError") {
        console.log("Request canceled");
      } else {
        error("increment", response.message);
        setTimeout(() => {
          error("decrement", response.message);
        }, 1500);
      }
      console.error("Error request");
      return;
    }
    handleSetPagination(response.pagination);
    setTableData(response.data);
  } catch (err) {
    console.log("🚀 ~ err:", err);
    if (
      err.toString().includes("NETWORK_CONNECTION") ||
      err.toString().includes("Failed to fetch")
    ) {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, 1500);
    } else {
      error("increment", "Lo sentimos ocurrió un error, intente más tarde");
      setTimeout(() => {
        error("decrement", "");
      }, 1500);
    }
    loading();
    if (err.message === "NETWORK_CONNECTION") {
      error(
        "increment",
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        error("decrement", "");
      }, 1500);
      return;
    }
    error("increment", "Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error("decrement", "");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  } finally {
    loading();
  }
};

const pagination_size = process.env.REACT_APP_PAGINATION_SIZE;

const INITIAL_VALUES = {
  search: "",
  start_date: dayjs().subtract(90, "day").format("YYYY-MM-DD"),
  end_date: dayjs().format("YYYY-MM-DD"),
  start_hours: formatHour(dayjs().hour()),
  end_hours: formatHour(dayjs().hour() + 1),
  response_time: [],
  days: "today",
  type_event: "",
  first_mount: true,
  all_nodes: "",
  all: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const INITIAL_PAGINATION = {
  CurrentPage: 2,
  TotalPage: null,
  PageSize: pagination_size,
  TotalCount: 12,
  HasPreviousPage: false,
  HasNextPage: true,
  NextPageNumber: 2,
  PreviousPageNumber: null,
};

const paginationReducer = (pagination, action) => {
  switch (action.type) {
    case "set_values": {
      return {
        ...action.values,
      };
    }
    case "SET_FIELD":
      return {
        ...pagination,
        [action.field]: action.value,
      };
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const mappingOrderKeys = {
  usuario: "Usuario",
  fechaHora: "FechaHora",
  tipoEvento: "TipoEvento",
  datosRegistrados: "DatosRegistrados",
};

const base64ToBlob = (base64, mime) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
};

export default function Body() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [pagination, dispatchPagination] = useReducer(
    paginationReducer,
    INITIAL_PAGINATION
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [eventLogsOptions, setEventLogsOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(new AbortController());
  const [orderConfig, setOrderConfig] = useState({
    key: "usuario",
    direction: "asc",
  });
  const { errorValues, handleError } = useErrorReducer();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Handlers
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleSetPagination = (values) => {
    dispatchPagination({
      type: "set_values",
      values,
    });
  };

  const setFieldPagination = (field, value) => {
    dispatchPagination({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleSetPagination(INITIAL_PAGINATION);
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleDaysChange = (values) => {
    handleSetPagination(INITIAL_PAGINATION);
    const { field, value } = values;
    setFieldValue(field, value);
  };

  const handleSelectChange = (values) => {
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleDatePickerChange = createDatePickerChangeHandler({
    setter: setFieldValue,
  });

  const last_24_hrs = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const this_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs()
          .subtract(dayjs().day() - 1, 'day')
          .format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const today = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue("start_date", dayjs().format('YYYY-MM-DD'));
      setFieldValue("start_hours", "00:00");
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_90_days = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const this_year = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(dayjs().month(), 'month').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_12_months = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const daysController = useMemo(
    () => ({
      last_24_hrs: last_24_hrs,
      this_week: this_week,
      last_week: last_week,
      today: today,
      last_90_days: last_90_days,
      this_year: this_year,
      last_12_months: last_12_months,
    }),
    [
      last_12_months,
      last_24_hrs,
      last_90_days,
      last_week,
      this_week,
      this_year,
      today,
    ]
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]();
    }
  }, [daysController, values.days]);

  const getLogs = async ({ page, ...props }) => {
    handleGetAllLogs({
      values: {
        current_page: page,
        limit: true,
        ...values,
        OrderAscending: props?.order ?? true,
        OrderBy: mappingOrderKeys[orderConfig.key],
        // OrderBy: props?.orderBy ?? "fechaHora",
      },
      signal: abortControllerRef,
      loading: () => handleLoading(),
      error: handleError,
      error_message: (msj) => {},
      handleSetPagination,
      setTableData,
      service: getAllLogsService,
    });
  };

  const handleSelectedPage = (page) => {
    getLogs({ page: page });
  };

  const handleNextPage = () => {
    getLogs({ page: pagination.CurrentPage + 1 });
  };

  const handlePreviousPage = () => {
    getLogs({ page: pagination.CurrentPage - 1 });
  };

  const handleRefreshData = () => {
    setRefreshData((prevState) => !prevState);
  };

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setEventLogsOptions,
      catalog: "event_logs",
      error: handleError,
      error_message: setErrorMessage,
    });
  }, []);

  // Effects
  useEffect(() => {
    getLogs({ page: 1 });
  }, [refreshData, values]);

  const handleOrderByColumns = async ({ sort_key, order }) => {
    await getLogs({ page: pagination.CurrentPage, order, orderBy: sort_key });
  };
  const handleExport = async () => {
    console.log(values);
    const data = await handleExportActivity({
      values: {
        limit: false,
        ...values,
        total_registers: pagination.TotalCount,
      },
      loading: () => handleLoading(),
      error: handleError,
      error_message: (msj) => {},
      service: getAllLogsService,
      signal: abortControllerRef.current.signal,
    });
    return data;
  };

  const handleDownloadPdf = async () => {
    try {
      const response = await exportBitacoraService({
        current_page: pagination.CurrentPage,
        search: values.search,
        start_date: values.start_date,
        end_date: values.end_date,
        start_hours: values.start_hours,
        end_hours: values.end_hours,
        limit: pagination.PageSize,
        signal: abortControllerRef.current.signal,
        total_registers: pagination.TotalCount,
        type_event: values.type_event,
      });

      if (response.isSucceed && response.data) {
        const blobFile = base64ToBlob(response.data, "application/pdf");
        saveAs(blobFile, "Bitacora.pdf");
        setSuccess(true);
        setSuccessMessage("La exportación de datos se ha realizado con éxito");
      } else {
        // Ajusta el mensaje de error basado en el contenido de response.messages
        const errorMessage = response.messages || "Error desconocido";
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error en handleDownloadPdf:", error.message);
      setError(true);
      setErrorMessage(error.message || "Ocurrió un error al exportar");
    } finally {
      setTimeout(() => {
        setSuccess(false);
        setError(false);
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const columns = [
    {
      name: "Usuario",
      selector: (row) => row?.usuario ?? "",
      className: "table_header_cell_activity",
      key_name: "usuario",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "171px",
        maxWidth: "171px",
        paddingLeft: "13px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "171px",
        maxWidth: "171px",
        paddingLeft: "13px",
      },
      cell_classname: "table_row_cell_activity",
    },
    {
      name: "Fecha/Hora",
      selector: (row) =>
        dayjs
          .utc(row?.fechaHora)
          .utcOffset(-6)
          .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
      className: "table_header_cell_activity",
      key_name: "fechaHora",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "140px",
        maxWidth: "169px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "132px",
        maxWidth: "169px",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
      },
      cell_classname: "table_row_cell_activity text-wrap",
    },
    {
      name: "Tipo de evento registrado",
      selector: (row) => row?.tipoEvento ?? "",
      className: "table_header_cell_activity",
      key_name: "tipoEvento",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "244px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "244px",
      },
      cell_classname: "table_row_cell_activity text-wrap",
    },
    {
      name: "Datos registrados",
      selector: (row) => row.datosRegistrados,
      className: "table_header_cell_activity",
      key_name: "datosRegistrados",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "436px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "436px",
      },
      cell_classname: "table_row_cell_activity text-wrap",
    },
  ];
  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        {pagination?.TotalCount > 10 && (
          <div className="container-fluid d-flex px-2 py-3 justify-content-center align-items-center">
            <div className="d-flex gap-2">
              <button
                disabled={!pagination.HasPreviousPage}
                onClick={handlePreviousPage}
                className={`rounded-circle d-flex justify-content-center align-items-center bg-transparent ${
                  pagination.HasPreviousPage
                    ? "button_pagination_active"
                    : "button_pagination_inactive"
                }`}
              >
                <ArrorPreviewPagination />
              </button>
              {pagination.HasPreviousPage && pagination.TotalPage <= 2 && (
                <button
                  onClick={() =>
                    handleSelectedPage(pagination.PreviousPageNumber)
                  }
                  className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                >
                  {pagination.PreviousPageNumber}
                </button>
              )}
              {pagination.TotalPage > 2 &&
                pagination.TotalPage - pagination.CurrentPage <= 1 && (
                  <>
                    <button
                      onClick={() => handleSelectedPage(1)}
                      className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                    >
                      {1}
                    </button>
                    {pagination.TotalPage > 3 && (
                      <button
                        disabled
                        className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                      >
                        ...
                      </button>
                    )}
                    {pagination.TotalPage - pagination.CurrentPage !== 1 && (
                      <button
                        onClick={() =>
                          handleSelectedPage(pagination.PreviousPageNumber)
                        }
                        className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                      >
                        {pagination.PreviousPageNumber}
                      </button>
                    )}
                  </>
                )}
              <button className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_active">
                {pagination.CurrentPage}
              </button>
              {pagination.HasNextPage && (
                <button
                  onClick={() => handleSelectedPage(pagination.NextPageNumber)}
                  className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                >
                  {pagination.NextPageNumber}
                </button>
              )}
              {pagination.TotalPage > 2 &&
                pagination.HasNextPage &&
                pagination.TotalPage - pagination.CurrentPage > 1 && (
                  <>
                    {pagination.TotalPage > 3 && (
                      <button
                        disabled
                        className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                      >
                        ...
                      </button>
                    )}
                    <button
                      onClick={() => handleSelectedPage(pagination.TotalPage)}
                      className="rounded-circle d-flex justify-content-center align-items-center bg-transparent button_pagination_inactive"
                    >
                      {pagination.TotalPage}
                    </button>
                  </>
                )}

              <button
                disabled={!pagination.HasNextPage}
                onClick={handleNextPage}
                className={`rounded-circle d-flex justify-content-center align-items-center bg-transparent ${
                  pagination.HasNextPage
                    ? "button_pagination_active"
                    : "button_pagination_inactive"
                }`}
              >
                <ArrorNextPagination />
              </button>
            </div>
          </div>
        )}
      </Table>
    );
  }, [dataTable]);

  return (
    <ActivityContext.Provider
      value={{
        eventLogsOptions,
        handleExport,
        handleDownloadPdf,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0 dco_actividad_table_container">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end pt-3"
        >
          {Permisos.verificarPermiso("Bitácora", "Buscar") && (
            <ToAccordion breackpoint={1030} icon={null} label="Filtros">
              <FilterForm
                handleDatePickerChange={handleDatePickerChange}
                handleChange={handleChange}
                handleDaysChange={handleDaysChange}
                handleSelectChange={handleSelectChange}
                values={values}
              />
            </ToAccordion>
          )}
          {Permisos.verificarPermiso("Bitácora", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <ExportData />
            </div>
          )}
        </div>
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {errorValues.error !== 0 && (
        <AlertaGeneral type={"error"}>{errorValues.message}</AlertaGeneral>
      )}
    </ActivityContext.Provider>
  );
}
