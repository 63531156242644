import React from "react";
import { createPortal } from "react-dom";

const ALERTS = {
  error: "error_custom_alert",
  success: "succes_custom_alert",
};

export default function Alert({ type, children }) {
  return createPortal(
    <div
      className="d-flex justify-content-center w-100 position-absolute top-0"
      style={{ marginTop: "28px" }}
    >
      <div
        className={`alert_container ${ALERTS[type]} custom_alerts d-flex justify-content-center`}
        role="alert"
      >
        {children}
      </div>
    </div>,
    document.getElementById("root")
  );
}
