const label_types = {
  "normal": {
    inside: "input_label_inside_input",
    outside: "input_label_outside_input"
  },
  "email": {
    inside: "input_label_inside_input",
    outside: "input_label_outside_input"
  },
  "text": {
    inside: "input_label_inside_input",
    outside: "input_label_outside_input"
  },
  "password": {
    inside: "input_label_inside_input",
    outside: "input_label_outside_input"
  },
  "search": {
    inside: "input_label_inside_input_search",
    outside: "input_label_outside_input_search"
  },
  "tel": {
    inside: "input_label_inside_input_phone",
    outside: "input_label_outside_input_phone"
  }
}
export default function InputLabel({
  inputError,
  inputValid,
  focused,
  type = "normal",
  children,
  ...props
}) {
  return (
    <div
      className={`position-absolute pe-none 
      ${props.rounded ? "input_label_small" : "input_label"}
      ${
        !focused ? label_types[type].inside : label_types[type].outside
      }
      ${inputError && "input_label_error"}`}
    >
      {children}
    </div>
  );
}
