import React, {
  useState,
  useReducer,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import CopyCell from "./CopyCell";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import ExportData from "./ExportData";
import Details from "./Details";
import ToAccordion from "../Accordion/ToAccordion";
import {
  createDatePickerChangeHandler,
  validateEmptyValues,
  handleGetCatalogs,
  formatHour,
  getEndDateAndTime
} from "../../services/data";
import { Permisos } from "../../services/permisos";
import {
  getDataTransactions,
  downloadTransactions,
  getNodeCatalog,
} from "../../services/api";
import utc from "dayjs/plugin/utc";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";

dayjs.extend(utc);

export const TransaccionContext = createContext();

const INITIAL_VALUES = {
  search: "",
  start_date: dayjs().subtract(90, "day").format("YYYY-MM-DD"),
  end_date: dayjs().format("YYYY-MM-DD"),
  start_hours: formatHour(dayjs().hour()),
  end_hours: formatHour(dayjs().hour() + 1),
  response_time: [],
  days: "last_90_days",
  first_mount: true,
  all_nodes: "",
  all: "",
};
const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export default function Body() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [scoreLimits, setScoreLimits] = useState({
    min: 10,
    max: 100,
  });
  const [responseTimeLimits, setResponseTimeLimits] = useState({
    min: 10,
    max: 100,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const [nodeCatalog, setNodeCatalog] = useState([]);
  const [orderConfig, setOrderConfig] = useState({
    key: "Fecha_Hora_Transaccion",
    direction: "desc",
  });

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  // Handlers
  const handleLoading = (state) => {
    setLoading(state);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = useCallback((msj, errorValue) => {
    setErrorMessage(msj);
    handleError(errorValue);
  }, []);

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = useCallback((msj) => {
    setSuccessMessage(msj);
  }, []);

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleResetPagination();
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleDaysChange = (values) => {
    handleResetPagination();
    const { field, value } = values;
    setFieldValue(field, value);
  };

  const handleSelectChange = (values) => {
    handleResetPagination();
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleDatePickerChange = createDatePickerChangeHandler({
    setter: setFieldValue,
    startDate: values.start_date,
    endDate: values.end_date,
  });

  const minEndDate = values.start_date ? dayjs(values.start_date) : undefined;
  const maxStartDate = values.end_date ? dayjs(values.end_date) : undefined;

  const last_24_hrs = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const this_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs()
          .subtract(dayjs().day() - 1, 'day')
          .format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_week = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const today = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue("start_date", dayjs().format('YYYY-MM-DD'));
      setFieldValue("start_hours", "00:00");
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_90_days = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const this_year = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(dayjs().month(), 'month').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );
  const last_12_months = useCallback(
    () => {
      const endDateAndHours = getEndDateAndTime();
      setFieldValue(
        "start_date",
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      );
      setFieldValue("start_hours", formatHour(dayjs().hour()));
      setFieldValue("end_date", endDateAndHours.end_date);
      setFieldValue("end_hours", endDateAndHours.end_time);
    },
    [],
  );

  const daysController = useMemo(
    () => ({
      last_24_hrs: last_24_hrs,
      this_week: this_week,
      last_week: last_week,
      today: today,
      last_90_days: last_90_days,
      this_year: this_year,
      last_12_months: last_12_months,
    }),
    [
      last_12_months,
      last_24_hrs,
      last_90_days,
      last_week,
      this_week,
      this_year,
      today,
    ]
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]();
    }
  }, [daysController, values.days]);

  const handleGetTransacions = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      handleLoading(true);
      const noEmptyValues = validateEmptyValues(values);
      const response = await getDataTransactions({
        page: pagination.page,
        ...noEmptyValues,
        signal: abortControllerRef.current.signal,
        OrderAscending: orderConfig.direction,
        OrderBy: orderConfig.key,
        ...(noEmptyValues.all_nodes === "Todos los nodos" ? {all_nodes: ""} : {all_nodes: noEmptyValues.all_nodes} )
      });
      if (!response.success) {
        if (response.name === "AbortError") {
          console.log("Request canceled");
        } else {
          if (!error) {
            handleErrorMessage(response?.message);
            setTimeout(() => {
              handleErrorMessage("");
            }, 1500);
          }
        }
        handleLoading(false);
        setTableData([]);
        return;
      }
      setTableData(response.data?.payload?.items?.list ?? []);
      handleValidateTotalPages({
        totalPages: response.data?.payload?.items?.totalPages,
        page: response.data?.payload?.items?.page,
        totalElements: response.data?.payload?.items?.totalElements,
      });
      setScoreLimits({
        min: response.data?.payload?.items?.min_super_score,
        max: response.data?.payload?.items?.max_super_score,
      });
      setResponseTimeLimits({
        max: response.data?.payload?.items?.max_tiempo_respuesta,
        min: response.data?.payload?.items?.min_tiempo_respuesta,
      });
      handleLoading(false);
      return;
    } catch (error) {
      console.log("Error en el handler");
      console.error(error);
      if (error.name === "AbortError") {
        console.log("Request canceled");
      } else {
        handleErrorMessage("Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleErrorMessage("");
        }, 1500);
      }
      handleLoading(false);
      setTableData([]);
      return;
    }
  }, [
    handleErrorMessage,
    handleValidateTotalPages,
    pagination.page,
    values,
    orderConfig,
  ]);

  const availableDownloads = useMemo(
    () => ({
      excel: {
        params: "&formato=xls",
        name: "Transacciones.xls",
      },
      csv: {
        params: "&formato=csv",
        name: "Transacciones.csv",
      },
      pdf: {
        params: "&formato=pdf",
        name: "Transacciones.pdf",
      },
    }),
    []
  );

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        handleLoading(true);
        const response = await downloadTransactions({
          ...values,
          download: availableDownloads[selected],
        });
        if (!response.success) {
          handleLoading(false);
          handleErrorMessage(response.message, true);
          setTimeout(() => {
            handleErrorMessage("", false);
          }, 1500);
          return;
        }
        handleLoading(false);
        handleSuccess();
        handleSuccessMessage(
          "La exportación de datos se ha realizado con éxito"
        );
        setTimeout(() => {
          handleSuccess();
          handleModal();
          handleSuccessMessage("");
        }, 1500);
      } catch (error) {
        console.log(error);
        handleLoading(false);
        handleErrorMessage("Lo sentimos ocurrió un error", true);
        setTimeout(() => {
          handleErrorMessage("", false);
        }, 1500);
        return;
      }
    },
    [availableDownloads, handleErrorMessage, handleSuccessMessage, values]
  );

  useEffect(() => {
    handleGetTransacions();
  }, [handleGetTransacions, pagination.page, values, orderConfig]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Nodo",
        custom_cell: (data) => data?.idNodo ?? "",
        selector: (data) => data?.idNodo ?? "",
        className: "table_header_cell_transactions",
        key_name: "idNodo",
        service: handleOrderByColumns,
        style: {
          minWidth: "125px",
          maxWidth: "125px",
        },
        cell_styles: {
          minWidth: "125px",
          maxWidth: "125px",
        },
        cell_classname: "table_row_cell_management",
      },
      {
        name: "ID transacción",
        custom_cell: (data) =>
          data?.idTransaccion ? <CopyCell text={data.idTransaccion} /> : "",
        selector: (data) => data?.idTransaccion ?? "",
        service: handleOrderByColumns,
        key_name: "idTransaccion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "155px",
          maxWidth: "155px",
        },
        cell_styles: {
          minWidth: "155px",
          maxWidth: "155px",
        },
        cell_classname: "table_row_cell_management",
      },
      {
        name: "Fecha/Hora",
        service: handleOrderByColumns,
        selector: (data) =>
          dayjs
            .utc(data?.Fecha_Hora_Transaccion)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        key_name: "Fecha_Hora_Transaccion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "184px",
          maxWidth: "184px",
        },
        cell_styles: {
          minWidth: "184px",
          maxWidth: "184px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Tiempo de respuesta",
        service: handleOrderByColumns,
        selector: (data) => `${data?.tiempo_respuesta/1000 ?? ""} seg`,
        key_name: "tiempo_respuesta",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "162px",
          maxWidth: "162px",
        },
        cell_styles: {
          minWidth: "162px",
          maxWidth: "162px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Índice calidad de fotografía",
        custom_cell: (data) =>
          parseFloat(data?.IndiceCalidad_Fotografia ?? 0) ?? "",
        selector: (data) =>
          parseFloat(data?.IndiceCalidad_Fotografia ?? 0) ?? "",
        key_name: "IndiceCalidad_Fotografia",
        service: handleOrderByColumns,
        className: "table_header_cell_transactions",
        style: {
          minWidth: "194px",
          maxWidth: "194px",
        },
        cell_styles: {
          minWidth: "194px",
          maxWidth: "194px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Validación",
        selector: (data) => data.validacion ?? "",
        service: handleOrderByColumns,
        key_name: "validacion",
        className: "table_header_cell_transactions",
        style: {
          minWidth: "115px",
          miaxWidth: "115px",
        },
        cell_styles: {
          minWidth: "115px",
          miaxWidth: "115px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    [handleOrderByColumns]
  );

  const conditionalColumn = useMemo(
    () => [
      {
        name: "Acciones",
        custom_cell: (data) => <Details data={data} />,
        className: "table_header_cell_transactions",
        style: {
          minWidth: "85px",
          maxWidth: "85px",
        },
        cell_styles: {
          minWidth: "85px",
          maxWidth: "85px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    []
  );
  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={[
          ...columns,
          ...(Permisos.verificarPermiso("Transacciones", "Ver detalle")
            ? conditionalColumn
            : []),
        ]}
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        data={dataTable}
        header_classname="table_header"
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    conditionalColumn,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
  ]);

  return (
    <TransaccionContext.Provider
      value={{
        dataTable,
        setError,
        setErrorMessage,
        handleDownloadData,
        nodeCatalog,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
        >
          {Permisos.verificarPermiso("Transacciones", "Buscar") && (
            <ToAccordion breackpoint={1030} icon={null} label="Filtros">
              <FilterForm
                handleDatePickerChange={handleDatePickerChange}
                handleChange={handleChange}
                handleDaysChange={handleDaysChange}
                handleSelectChange={handleSelectChange}
                values={values}
                scoreLimits={scoreLimits}
                responseTimeLimits={responseTimeLimits}
                minEndDate={minEndDate}
                maxStartDate={maxStartDate}
              />
            </ToAccordion>
          )}
          {Permisos.verificarPermiso("Transacciones", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <ExportData />
            </div>
          )}
        </div>
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </TransaccionContext.Provider>
  );
}
