import React, { useContext, useEffect } from 'react';
import DatePickerInput from '../Inputs/DatePickerInput';
import dayjs from 'dayjs';
import { NodeDashboardContext } from './NodoStatisticsData';
import { daysOptions, hoursOptions } from '../../services/data';
import ClockIcon from '../Sharing/ClockIcon';
import Select from '../Users/Select';

const useDashboardNodeContext = () => {
  const context = useContext(NodeDashboardContext);
  
  const nodesCatalog = context?.nodesCatalog || null;
  
  return {
    ...context,
    nodesCatalog,
  };
};


export default function FilterDashboard({
  type = 'consorcio',
  values,
  handleDatePickerChange = () => {},
  handleChange = () => {},
}) {
  const { nodesCatalog } = useDashboardNodeContext();

  return (
    <form
      className='container-fluid px-1 justify-content-end d-flex gap-1 gestion_filter_form'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {type === 'node' && (
        <div style={{ maxWidth: '98px' }} className='container-fluid p-0 m-0'>
          <Select
            id={`node_name`}
            name={`node_name`}
            label=''
            value={
              values.node_name
            }
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            options={nodesCatalog}
            valueKey='nodo_name'
            labelKey='nodo_name'
            arrow='dark'
            arrowWidth={5}
            rounded={true}
          />
        </div>
      )}
      <div
        style={{ maxWidth: '96px' }}
        className='container-fluid p-0 m-0 datePicker-input-container'
      >
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.start_date}
          label='Desde'
          id='start_date'
          name='start_date'
          valid={true}
          rounded={true}
          minDate={dayjs().subtract(90, 'day')}
          maxDate={dayjs(values.end_date) ?? dayjs()}
        />
      </div>
      <div style={{ maxWidth: '80px' }} className='container-fluid p-0 m-0'>
        <Select
          id={`start_hours`}
          name={`start_hours`}
          label=''
          value={values.start_hours}
          handleSetValue={({ field, value }) => {
            handleChange({
              name: field,
              value,
            });
          }}
          options={hoursOptions}
          valueKey='value'
          labelKey='label'
          arrow='white'
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      <div
        style={{ maxWidth: '96px' }}
        className='container-fluid p-0 m-0 datePicker-input-container'
      >
        <DatePickerInput
          onChange={handleDatePickerChange}
          value={values.end_date}
          id='end_date'
          name='end_date'
          label={'Hasta:'}
          valid={true}
          rounded={true}
          minDate={dayjs(values.start_date) ?? null}
          maxDate={dayjs()}
        />
      </div>
      <div style={{ maxWidth: '80px' }} className='container-fluid p-0 m-0'>
        <Select
          id={`end_hours`}
          name={`end_hours`}
          label=''
          value={values.end_hours}
          handleSetValue={({ field, value }) => {
            handleChange({
              name: field,
              value,
            });
          }}
          options={hoursOptions}
          valueKey='value'
          labelKey='label'
          arrow='white'
          rounded={true}
          icon={<ClockIcon />}
        />
      </div>
      <div style={{ maxWidth: '65px' }} className='container-fluid p-0 m-0'>
        <Select
          id={`days`}
          name={`days`}
          label=''
          value={values.days}
          handleSetValue={({ field, value }) => {
            handleChange({
              name: field,
              value,
            });
          }}
          options={daysOptions}
          valueKey='value'
          labelKey='label'
          rounded={true}
          max_width={65}
          arrowWidth={5}
        />
      </div>
      <button type='submit' className='d-none'></button>
    </form>
  );
}
