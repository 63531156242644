import React from "react";
//Librerías
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default function Rows({ columns, row, row_classname, row_styles, index: index_main}) {
  return (
    <tr
      className="container-fluid p-0 d-flex"
      {...(row_classname && { className: row_classname })}
      {...(row_styles && { style: row_styles })}
    >
      {columns.map(
        ({ selector, custom_cell, cell_styles, cell_classname, key_name }, index) => (
          <td
            key={`table-row-cell-${index}-${generateUUID()}`}
            {...(cell_classname && { className: cell_classname })}
            {...(cell_styles && { style: cell_styles })}
          >
            {custom_cell ? custom_cell({...row, index: index_main}) : selector(row)}
          </td>
        )
      )}
    </tr>
  );
}
