import { useState, useEffect } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from './ArrowDropDownIcon';

const useUUID = () => {
  const generateUUID = () => {
    const randomHex = () => Math.floor(Math.random() * 16).toString(16);
  
    const segments = [
      randomHex() + randomHex(),
      randomHex(),
      '4' + randomHex().substr(0, 3),
      randomHex().substr(0, 4),
      randomHex().substr(0, 4) + randomHex().substr(0, 4) + randomHex().substr(0, 4),
    ];
  
    return segments.join('-');
  }
  
  return generateUUID();
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};



export default function ToAccordion({ breackpoint, icon, label, children }) {
  const windowWidth = useWindowWidth();
  const content = useUUID();
  const id = useUUID();
  return (
    <>
      {windowWidth <= breackpoint ? (
        <Accordion style={{width: "100%", marginTop:'10px'}}>
          <AccordionSummary
            expandIcon={icon ?? <ArrowDropDownIcon />}
            aria-controls={content}
            id={id}
          >
            {label}
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      ) : (
        children
      )}
    </>
  );
}
