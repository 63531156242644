import React from 'react'
import LoginCard from "../components/Login/LoginCard";
import FormNewPasswordRecuperar from "../components/RecuperarPassword/FormNewPasswordRecuperar";

export default function RestorePassword() {
  return (
    <LoginCard>
        <FormNewPasswordRecuperar />
    </LoginCard>
  )
}
