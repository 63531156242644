import React, { useEffect, useState, useRef } from "react";

const InputSelect = ({
  setValueProp = () => {},
  labelProp = "Área",
  options = [
    { id: 0, name: "Por Definir" },
    { id: 1, name: "Area 1" },
    { id: 1, name: "Area 2" },
  ],
  idKey = "id",
  labelKey = "name",
  rounded,
  initial_value = "",
  max_width,
  icon = null,
}) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [label, setLabel] = useState("");
  const [initialValueSet, setInitialValueSet] = useState(false);

  useEffect(() => {
    setValueProp(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    // if (labelProp === "Puesto"){
    if (labelProp){
      const newLabel = options.find(option => option.id === initial_value);
      setLabel(newLabel ? newLabel.name : 'Por Definir')
    }
  }, [label]);

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
    setLabel(value[labelKey]);
    setIsOpen(false);
  };

  useEffect(() => {
    setLabel("");
  }, [options]);

  useEffect(() => {
    if (initial_value !== "" && !initialValueSet) {
      const selected = options.filter(
        (object) => object[idKey] === initial_value
      );
      
      if (selected && selected.length > 0) {
        setSelectedOption(selected[0]);
        setLabel(selected[0][labelKey]);
        setInitialValueSet(true);
      }
    }
  }, [initial_value, options]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setIsOpen]);

  return (
    <>
      <div
        ref={containerRef}
        // className="container-fluid p-0 mt-3 pe-none opacity-50 position-relative"
        className="container-fluid p-0 mt-3 position-relative"
      >
        <div
          className={`position-absolute pe-none overflow-hidden
          ${rounded ? "input_label_small" : "input_label"}
                ${
                  label
                    ? "input_label_outside_input"
                    : "input_label_inside_input"
                }
                false`}
        >
          {labelProp}
        </div>
        <div
          onClick={handleClick}
          style={{
            border: rounded ? "1px solid #D9D9D9" : "1px solid #000000",
          }}
          className={`${
            rounded ? "input_select_small rounded-pill" : "input_select"
          }  d-flex overflow-hidden justify-content-start ${
            rounded ? "rounded-pill" : ""
          }`}
        >
          <span
            className="overflow-hidden"
            style={{ maxWidth: `${max_width - 30}px`, textWrap: "nowrap" }}
          >
            {label}
          </span>
        </div>
        {icon ? (
          <img
            src={icon}
            width="10"
            height="auto"
            className={`${"input_select_icon_small"}`}
            alt="Select icon arrow"
            style={{ cursor: "pointer", width: "10px" }}
          />
        ) : (
          <img
            src="/images/dicio/arrow_down.webp"
            width="10"
            height="auto"
            className={`${
              rounded ? "input_select_icon_small" : "input_select_icon"
            } ${
              isOpen
                ? rounded
                  ? "input_select_icon_open_small"
                  : "input_select_icon_open"
                : ""
            }`}
            alt="Select icon arrow"
            style={{ cursor: "pointer" }}
          />
        )}
        {isOpen && options.map && (
          <div
            className={`${
              rounded
                ? "input_select_option_container_small"
                : "input_select_option_container_small"
            } shadow`}
          >
            {options.map((obj, index) => (
              <div
                style={{ textWrap: "nowrap" }}
                key={`${obj[idKey]}-${index}`}
                className={`d-flex justify-content-start
                ${
                  rounded ? "input_select_option_small" : "input_select_option"
                }
                ${obj?.disabled ? 'disabled_input_select' : ''} 
                z-3`}
                onClick={() => handleChange(obj)}
              >
                {obj[labelKey]}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default InputSelect;
