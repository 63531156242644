import React, { useState } from "react";

const OptionButton = ({
  title = "",
  subtitle = "",
  buttonContent = "",
  onClick = () => {},
}) => {
  return (
    <>
      <div className="col-6 d-flex align-items-center first-column">
        <p className="fw-bold m-0" style={{ fontSize: "10px" }}>
          {title + " "}
          <span
            style={{
              fontSize: "8px",
              color: "var(--settings-description-color)",
              fontWeight: "700",
            }}
          >
            {subtitle}
          </span>
        </p>
      </div>
      <div className="col-6 col-lg-2 d-flex align-items-center second-column">
        <button
          type="button"
          className="button button_active"
          style={{ fontSize: "9px", fontWeight: "400", padding: "6px 7px" }}
          onClick={onClick}
        >
          {buttonContent}
        </button>
      </div>
    </>
  );
};

export default OptionButton;
