import React, { useRef } from "react";
import Modal from "../Modal/Modal";

export default function ChangeUserStatusConfirmation({
  setFinished,
  finished,
  name,
  actionButtonRef,
  title,
  actionFunction,
  subtitle,
  finishedTitle,
  finishedSubtitle,
  cancelFunction,
  handleRefreshData
}) {
  const modalRef = useRef(null);

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
    setFinished(false);
  };

  return (
    <>
      <Modal ref={modalRef}>
        {!finished && (
          <article
            style={{
              gap: "24px",
              paddingTop: "58px",
              paddingLeft: "94px",
              paddingRight: "86px",
              width: "477px",
              minWidth: "0px",
            }}
            className="delete_user_card bg-white rounded m-auto"
          >
            <p className="card_title" style={{ marginBottom: "24px" }}>
              {title}
            </p>
            <p className="card_text" style={{ marginBottom: "39px" }}>
              {subtitle}
            </p>
            <div
              style={{
                padding: "11px 10px",
                marginBottom: "43px",
                fontSize: "12px",
              }}
              className="contianer-fluid border fw-bold border-dark rounded"
            >
              {name}
            </div>
            <div className="container-fluid p-0 d-flex gap-2">
              <button
                onClick={actionFunction}
                className="button button_active"
                type="button"
              >
                Confirmar
              </button>
              <button
                className="button cancel_button"
                onClick={() => {
                  cancelFunction();
                  handleModal();
                }}
                type="button"
              >
                Cancelar
              </button>
            </div>
          </article>
        )}
        {finished && (
          <article
            style={{ gap: "24px" }}
            className="delete_user_card_success bg-white rounded m-auto d-flex flex-column"
          >
            <div
              className={
                "d-flex flex-column justify-content-start align-items-start gap-3"
              }
            >
              <h4 className={"card_title"}>{finishedTitle}</h4>
              <p className={"card_text"}>{finishedSubtitle}</p>
              <div
                className={
                  "d-flex p-0 container-fluid justify-content-start gap-3 mt-2"
                }
              >
                <button
                  onClick={() => {
                    handleModal();
                    handleRefreshData()
                  }}
                  type="button"
                  className={"button button_active"}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </article>
        )}
      </Modal>
      <button
        className="d-none"
        type="button"
        onClick={handleModal}
        ref={actionButtonRef}
      ></button>
    </>
  );
}
