import React from "react";
import EditUser from "./EditUser";
import { Permisos } from "../../services/permisos";

export default function TableActions({ id, name, status }) {
  return (
    <div style={{gap: "10px"}} className="d-flex flex-wrap container-fluid p-0 table_user_container_actions">
      {Permisos.verificarPermiso("Usuario", "Actualizar") && (
        <EditUser id={id} status={status}>
          Editar
        </EditUser>
      )}
    </div>
  );
}
