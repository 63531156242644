import React, { useState } from "react";

const ModalCardPages = ({ children, classModal = "" }) => {
  return (
    <div
      className={
        "modal_container position-fixed  w-100  top-0  start-0  overflow-x-hidden overflow-y-auto min-vh-100 " +
        classModal
      }
    >
      <div className="modal_card_container change_password_modal modal_ajustes_pasword">
        <div className="overflow-x-hidden mx-auto">
          <div className="row m-0" style={{ minWidth: "200px" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCardPages;
