import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useCallback,
} from "react";
import AssignRolesForm from "./AssignRolesForm";
import {
  activateUserService,
  getAllRolesService,
  changeUserStatusService,
} from "../../services/api";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { UserContext } from "./Body";

const INITIAL_VALUES = {
  roleIds: [],
  userId: "",
};

export const handleActivate = async ({
  id,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleRefreshData
  }) => {
  try {
    loading();
    const response = await service({ id, status: 1 });
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, 1500);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    setTimeout(() => {
      success();
      success_message("");
      handleRefreshData();
    }, 1500);
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const useUserContext = () => {
  try {
    const context = useContext(UserContext);
    if (!context) {
      throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
  } catch (error) {
    return {
      handleRefreshData: () => {
        console.warn(
          "handleRefreshData is not available because UserProvider is missing"
        );
      },
    };
  }
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      if (action.field === "roleIds") {
        const { roleIds } = state;
        if (roleIds.includes(action.value)) {
          return {
            ...state,
            roleIds: roleIds.filter((item) => item !== action.value),
          };
        } else {
          return {
            ...state,
            roleIds: [...roleIds, action.value],
          };
        }
      }
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export const handleActivateUsers = async ({
  values,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleActiveUser,
  handleRefreshData,
}) => {
  try {
    loading(true);
    const response = await service(values);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, 1500);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    setTimeout(() => {
      success();
      success_message(response.message);
      handleRefreshData();
      handleActiveUser();
    }, 1500);
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, 2000);
    console.error("Error request");
    console.error(err);
    return;
  }
};

export default function ActivateUser({ id, handleActiveUser, status }) {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [rolCatalog, setRolCatalog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { handleRefreshData } = useUserContext();

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };
  const resetFieldValue = (field, value) => {
    dispatch({ type: "RESET_FIELD", field, value });
  };
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleCheckbox = (e) => {
    const { value, name } = e.target;
    setFieldValue("roleIds", name);
  };

  const handleResetRoleIds = () => {
    resetFieldValue("roleIds", []);
  };

  const handleRolCatalog = async () => {
    try {
      handleLoading();
      const response = await getAllRolesService();
      if (!response.success) {
        handleLoading();
        console.error(response.message);
        return;
      }
      handleLoading();
      setRolCatalog(response.data);
      return;
    } catch (error) {
      handleLoading();
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleActivateUsers({
      values: {
        ...values,
        status,
      },
      loading: handleLoading,
      error: handleError,
      error_message: handleErrorMessage,
      success: handleSuccess,
      success_message: handleSuccessMessage,
      service: activateUserService,
      handleActiveUser,
      handleRefreshData,
    });
  };

  const ActivateUser = useCallback(async () => {
    await handleActivate({
      id,
      service: changeUserStatusService,
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      success: () => handleSuccess(),
      success_message: (msj) => handleSuccessMessage(msj),
      handleRefreshData
    });
  }, [handleRefreshData, id]);

  useEffect(() => {
    handleRolCatalog();
    setFieldValue("userId", id);
  }, []);

  useEffect(() => {
    setIsValid(values?.roleIds.length > 0);
  }, [values]);

  useEffect(() => {
    if (status === 2) {
      ActivateUser();
    }
  }, [ActivateUser, status]);

  return (
    <>
      {status === 0 && (
        <AssignRolesForm
          handleResetRoleIds={handleResetRoleIds}
          handleCheckbox={handleCheckbox}
          roles={rolCatalog}
          values={values.roleIds}
          onClick={handleSubmit}
          close={handleActiveUser}
        />
      )}
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
