import React, { useState, useEffect } from "react";
import { emailExist } from "../../services/api";

const useEmailExist = ({ email, valid }) => {
  const [exist, setExist] = useState(false);

  const validateEmail = async () => {
    try {
      const response = await emailExist(email);
      setExist(response);
    } catch (error) {
      setExist(false);
    }
  };

  useEffect(() => {
    if (valid && email !== "") {
      validateEmail();
    }
    if (email === "") {
      setExist(false);
    }
  }, [email, valid]);

  return exist;
};

export default function EmailValidation({ email, valid }) {
  const exist = useEmailExist({ email, valid });
  return (
    <>
      {(!valid || exist) && (
        <div className="container email_tooltip_validator">
          <p
            style={{ ...(!valid ? { color: "red" } : {}) }}
            className={`
          email_tootlip_validator_text 
          email_tootlip_validator_text_point
          ${
            !valid
              ? "email_tootlip_validator_text_error"
              : "email_tootlip_validator_text_neutral"
          }
          `}
          >
            Correo ingresado invalido
          </p>
          <p
            style={{ ...(exist ? { color: "red" } : {}) }}
            className={`
          email_tootlip_validator_text 
          email_tootlip_validator_text_point
          ${
            exist
              ? "email_tootlip_validator_text_error"
              : "email_tootlip_validator_text_neutral"
          }
          `}
          >
            El correo ya se encuentra registrado en el sistema
          </p>
        </div>
      )}
    </>
  );
}
