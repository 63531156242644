import React from "react";
import { createPortal } from "react-dom";

export default function Loader() {
  return createPortal(
    <div style={{ background: "transparent"}} className="loader_container d-flex justify-content-center align-items-center modal_container position-fixed w-100 top-0 start-0 h-100">
      <div className="loader">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
        <div className="bar6"></div>
        <div className="bar7"></div>
        <div className="bar8"></div>
      </div>
    </div>,
    document.getElementById("root")
  );
}
