import React, { useState, useEffect } from "react";
import ModalCardPagesSmall from "../Modal/ModalCardPagesSmall";
import { createBrowserHistory } from "history";

export default function VerificarPassword() {
  const [endOfTime, setEndOfTime] = useState(false);
  const [alert, setAlert] = useState(false);

  const history = createBrowserHistory();
  useEffect(() => {
    if (history)
      if (history.location)
        if (history.location.state)
          if (history.location.state.changePassword) return;

    verificarVigencia();
  }, []);

  const verificarVigencia = () => {
    const diasVigencia = 90;
    const rangoAviso = 5;
    let datePass = new Date(sessionStorage.getItem("lastPasswordChangedDate"));
    let diferenciaMs = new Date().getTime() - datePass.getTime();
    let horas = diferenciaMs / (1000 * 60 * 60);
    if (horas >= diasVigencia * 24) {
      setAlert(true);
      setEndOfTime(true);
    } else if (horas >= (diasVigencia - rangoAviso) * 24) {
      setAlert(true);
      setEndOfTime(false);
    }
  };
  const cambiarContrasenia = () => {
    history.replace("/ajustes", { changePassword: true });
    history.go();
  };
  const cancelar = () => {
    setAlert(false);
  };
  return (
    <>
      {alert && (
        <div
          className={`
        modal_container 
        position-fixed 
        w-100 
        top-0 
        start-0 
        overflow-x-hidden
        overflow-y-auto
        min-vh-100
      `}
        >
          <ModalCardPagesSmall className={"shadow-lg"}>
            <div
              className="overflow-x-hidden mx-auto notification-90-dias"
              style={{
                minWidth: "500px",
                alignItems: "center",
              }}
            >
              <div
                className="row m-0"
                style={{
                  width: "60%",
                  height: "60%",
                  maxWidth: "271px",
                }}
              >
                <div class="d-flex flex-column h-100 justify-content-between p-0">
                  <p
                    className={"card_title mb-0"}
                    style={{ marginTop: "60px" }}
                  >
                    Actualización de <br />
                    contraseña requerida{" "}
                  </p>
                  <p
                    className={"card_text"}
                    style={{ marginTop: "18px", marginBottom: "47px" }}
                  >
                    {endOfTime
                      ? "Tu contraseña ha cumplido 90 días de vigencia, es momento de actualizarla"
                      : "El plazo de 90 días para el cambio de contraseña, está por expirar"}
                  </p>
                  <div className="d-flex gap-3">
                    <button
                      className={`button button_active`}
                      style={{ maxWidth: "fit-content" }}
                      type="button"
                      onClick={cambiarContrasenia}
                    >
                      Actualizar ahora
                    </button>
                    {!endOfTime && (
                      <button
                        className={"cancel_button"}
                        type="button"
                        onClick={cancelar}
                      >
                        Cancelar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalCardPagesSmall>
        </div>
      )}
    </>
  );
}
