import React, { useEffect, useRef, useState } from 'react';
import TituloPrincipal from "../../Dashboard/TituloPrincipal";
import c3 from "c3";

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowWidth;
};

const ResultValidationChart = ({
    data = [],
    titulo = 'Resultados de la validación',
    heightGeneral,
    widthGeneral,
    size,
    flexFlow,
    minHeight,
    setRef,
    refKey,
    getIdDivGraphic
}) => {
    const chartRef = useRef(null);
    const windowWidth = useWindowWidth();
    const uniqueId = `chart_result_validation_node`;

    useEffect(() => {
        getIdDivGraphic(uniqueId, "resultValidNode");
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            let dataArray = [
                ['No existe'],
                ['Sin coincidencia'],
                ['Coincidencia']
            ];
            for (let index = 0; index < data.length; index++) {
                data[index].forEach(element => {
                    dataArray[index].push(element.count)
                });
            }

            const chart = c3.generate({
                bindto: `#${uniqueId}`,
                data: {
                    columns: dataArray,
                    type: 'bar',
                },
                bar: {
                    width: {
                        ratio: 0.90,
                        space: 0.2
                    }
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                axis: {
                    y: {
                        label: {
                            text: "Porcentaje de las transacciones",
                            position: "outer-middle",
                        },
                    },
                    x: {
                        label: {
                            text: "Horas",
                            position: "outer-center",
                        },
                        type: "category",
                        tick: {
                            format: function (x) {
                                return `${(x + 1)?.toString().padStart(2, "0")}`;
                            },
                            width: 20
                        },
                    },
                },
                color: {
                    pattern: ["#D9D9D9", "#1B80F2", "#805dfe"],
                },
                size: size,
                legend: {
                    show: false,
                },
            });

            return () => {
                chart.destroy();
            };
        }
    }, [data])

    useEffect(() => {
        if (setRef && refKey) {
            setRef(refKey, chartRef.current);
        }
    }, [setRef, refKey]);

    return (
        <div
            className={"dashboard_cards dashboard_chart_cards"}
            style={{
                width: "100%",
                height: heightGeneral,
                ...(windowWidth >= 1280
                    ? widthGeneral
                        ? { width: widthGeneral }
                        : {}
                    : flexFlow
                        ? { flex: flexFlow }
                        : {}),
                ...(minHeight ? { minHeight: minHeight } : {}),
            }}
        >
            {/* <div> */}
                <div className="d-flex flex-row mb-3">
                    <TituloPrincipal titulo={titulo} />
                </div>
            {/* </div> */}

            <div className="d-flex flex-row justify-content-end align-self-center mb-1">
                <div className="dco_graficas-subtitle mx-3 d-flex flex-row">
                    <div
                        className="d-flex mx-1"
                        style={{
                            width: "7px",
                            height: "7px",
                            borderRadius: "50%",
                            backgroundColor: "#D9D9D9",
                            marginTop: "1px",
                        }}
                    ></div>
                    No existe
                </div>
                <div className="dco_graficas-subtitle mx-3 d-flex flex-row">
                    <div
                        className="d-flex mx-1"
                        style={{
                            width: "7px",
                            height: "7px",
                            borderRadius: "50%",
                            backgroundColor: "#1B80F2",
                            marginTop: "1px",
                        }}
                    ></div>
                    Sin coincidencia
                </div>
                <div className="dco_graficas-subtitle mx-3 d-flex flex-row">
                    <div
                        className="d-flex mx-1"
                        style={{
                            width: "7px",
                            height: "7px",
                            borderRadius: "50%",
                            backgroundColor: "#805dfe",
                            marginTop: "1px",
                        }}
                    ></div>
                    Coincidencia
                </div>
            </div>

            <div id={uniqueId} ref={chartRef}></div>
        </div>
    )

}

export default ResultValidationChart;