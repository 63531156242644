import React, { useState } from "react";
import EmailValidation from "./EmailValidation";

export default function Input({
  onChange,
  value,
  label,
  id,
  name,
  type,
  valid,
  rounded,
  tooltip = false,
  delete_button = false,
  reset_input = () => {},
}) {
  const [focused, setFocused] = useState(false);
  const inputError = !valid && value !== "";
  const handleFocus = () => {
    setFocused(!focused);
  };
  return (
    <div className="position-relative container-fluid p-0">
      <div
        className={`position-absolute pe-none 
        ${rounded ? "input_label_small" : "input_label"}
        ${inputError ? `text-danger` : ""}
        ${
          !(focused || value !== "")
            ? "input_label_inside_input"
            : "input_label_outside_input"
        }
      `}
      >
        {label}
      </div>
      <input
        id={id}
        name={name}
        type={type}
        onFocus={handleFocus}
        onBlur={handleFocus}
        onChange={onChange}
        autoComplete="off"
        value={value}
        style={
          delete_button
            ? { paddingRight: "30px", ...(!valid ? { color: "red" } : {}) }
            : {}
        }
        className={`bg-transparent
          ${rounded ? "rounded-pill input_small" : "input"} 
          ${
            inputError
              ? `border-danger text-danger`
              : rounded
              ? `border_for_small_inputs`
              : `border-black`
          }
          ${rounded ? "rounded-pill" : ""}
      `}
      />
      {delete_button && value !== "" && (
        <button
          onClick={reset_input}
          className="border border-0 bg-transparent position-absolute z-3 button_input_reset d-flex"
        >
          <img
            src="/images/icons/close_input.png"
            alt="Reset icon"
            width={"16px"}
            height={"16px"}
          />
        </button>
      )}
      {type === "email" && tooltip &&
        <EmailValidation valid={valid} email={value}/>
      }
    </div>
  );
}
