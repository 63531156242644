/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
/* Libraries */
import c3 from "c3";
import "../../../assets/c3-0.7.20/c3.min.css";
import * as d3 from "d3";
import "c3/c3.css";
import dayjs from "dayjs";
/* Components */
import TituloPrincipal from "../../Dashboard/TituloPrincipal";
import { opacityOptions } from "../../../services/data";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export default function TransactionsNodeChart({
  titulo = "Total de transacciones en el nodo: ",
  subtitulo = "",
  resumenNumero = "0",
  minHeight,
  widthGeneral,
  heightGeneral,
  axis,
  grid,
  legend,
  tooltip,
  subchart,
  zoom,
  color,
  size,
  padding,
  point,
  regions,
  transition,
  title,
  data = [],
  flexFlow,
  setRef,
  refKey,
  onOptionsClick = () => { },
  getIdGraphic,
  ...rest
}) {
  const chartRef = useRef(null);
  const windowWidth = useWindowWidth();
  const uniqueId = `chart_historico_numero`;
  // const [copyData, setCopyData] = useState(null);

  useEffect(() => {
    getIdGraphic(uniqueId, "historicoNumeroTotalUnico");
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      let indicesX = ["x"];
      let indicesY = ["Transacciones"];
      let valuesData = data;

      for (let index = 1; index <= valuesData.length; index++) {
        indicesX.push(`${index}`);
        indicesY.push(valuesData[index - 1]?.count);
      }

      let sortData = Array.from(valuesData);
      sortData.sort((a, b) => b.count - a.count);
      let colorsOptions = [];
      sortData.forEach((item, index) => {
        colorsOptions[Number(item.count).toFixed(0)] = opacityOptions[index];
      });

      const chart = c3.generate({
        bindto: `#${uniqueId}`,
        data: {
          xs: {
            Transacciones: "x",
          },
          columns: [indicesX, indicesY],
          type: "bar",
          color: function (color, d) {
            if (typeof(d) === "object")
              return colorsOptions[d.value];
          },
        },
        bar: {
          width: {
            ratio: 0.85,
            space: 0.2
          }
        },
        grid: {
          y: {
            show: true,
          },
        },
        axis: {
          y: {
            label: {
              text: "Transacciones",
              position: "outer-middle",
            },
          },
          x: {
            label: {
              text: "Horas",
              position: "outer-center",
            },
            type: "category",
            tick: {
              format: function (x) {
                return `${(x + 1)?.toString().padStart(2, "0")}`;
              },
            },
          },
        },
        color: {
          pattern: ["#6236FF"],
        },
        legend: {
          show: false,
        },
        tooltip: {
          contents: function (e) {
            return `<div class="tooltip_charts_dashboard">${e[0].value
              } Transacciones ${dayjs(data[e[0].index].inicio).format(
                "DD/MM/YYYY HH:mm"
              )} - ${dayjs(data[e[0].index].fin).format(
                "DD/MM/YYYY HH:mm"
              )}</div>`;
          },
        },
        subchart: subchart,
        zoom: zoom,
        size: size,
        padding: padding,
        regions: regions,
        transition: transition,
        title: title,
        ...rest,
      });

      return () => {
        chart.destroy();
      };
    }
  }, [
    data,
    axis,
    grid,
    legend,
    tooltip,
    subchart,
    zoom,
    color,
    size,
    padding,
    point,
    regions,
    transition,
    title,
    rest,
  ]);

  useEffect(() => {
    if (setRef && refKey) {
      setRef(refKey, chartRef.current);
    }
  }, [setRef, refKey]);

  return (
    <div
      className={"dashboard_cards dashboard_chart_cards"}
      style={{
        width: "100%",
        height: heightGeneral,
        ...(windowWidth >= 1280
          ? widthGeneral
            ? { width: widthGeneral }
            : {}
          : flexFlow
            ? { flex: flexFlow }
            : {}),
        ...(minHeight ? { minHeight: minHeight } : {}),
      }}
    >
      <div>
        <div className="d-flex flex-row mx-3 mb-3">
          <TituloPrincipal titulo={titulo} number={resumenNumero} />
        </div>
      </div>

      <div id={uniqueId} ref={chartRef}></div>
    </div>
  );
}
