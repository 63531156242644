import React, { useState } from "react";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { formatTextTrx } from "../../services/data";

const useRowCopy = () => {
  const [copied, setCopied] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles:", error);
        setCopied(false);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      });
  };

  return {
    handleCopy,
    copied,
    showCopied,
  };
};

export default function CopyCell({ text, padding = null }) {
  const { handleCopy, copied, showCopied } = useRowCopy();

  return (
    <>
      <div className="container row p-0 m-0 d-flex align-items-center">
        <div style={{ ...(padding ? { padding } : {})}} className="col-8">
          <p className="m-0" style={{ fontSize: "10px" }}>{formatTextTrx(text, 13)}</p>
        </div>
        <div className="col-4 p-0">
          <button onClick={() => handleCopy(text)} className="copy_button d-flex">
            <img
              src="/images/icons/copy_icon.png"
              alt="Copy icon"
              width={"9px"}
              height={"9.09px"}
            />
          </button>
        </div>
      </div>
      {showCopied && (
        <AlertaGeneral type={copied ? "success" : "error"}>{copied ? 'Hemos copiado el dato a tu portapapeles.' : 'Lo sentimos, no hemos copiado el dato a tu portapapeles.'}</AlertaGeneral>
      )}
    </>
  );
}
